<template>
  <div class="profile h-full">
    <div class="w-full h-40 bg-primary">
    </div>
    <NavBar
      :links="navLinks"
      backgroundColor="bg-primary"
      textColor="text-white"
      backgroundActiveColor="bg-white"
      textActiveColor="text-primary"
      @onClick="updateNav"
      leftAligned
    />

    <!-- Profile -->
    <div v-if="navLinks[0].active" class="text-primary">
      <div class="p-8 md:px-40 md:py-24">
        <div class="w-full">
          <h1 class="text-3xl md:text-6xl font-black leading-none">Hi {{this.$store.getters.user.name}}</h1>
          <h3 class="text-base md:text-3xl text-kitchen font-extrabold">you're a legend.</h3>
        </div>
        <div class="w-full mt-4 border-primary border-b">

          <!-- tab -->
          <div @click="toggleTab(accordianData[0])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianData[0].name}}</p>
            <PlusIconTwo v-if="!accordianData[0].showing" isLink />
            <div v-if="accordianData[0].showing">
              <MinusIconTwo v-if="!profileLoading" />
              <LoadingIconTwo v-if="profileLoading" />
            </div>
          </div>
          <div v-if="accordianData[0].showing" class="py-4 px-4 flex flex-wrap">
            <div class="w-full lg:w-1/2">
              <div
                v-for="(goal, index) in goals"
                :key="index"
                class="flex items-center content-center justify-between mb-4 lg:pr-12"
              >
                <div class="flex items-center">
                  <GoalIcon heightClass="h-14 md:h-18" />
                  <!-- <div class="w-12 h-12 md:w-18 md:h-18 border border-primary rounded-full flex items-center justify-center">
                    <h6 class="text-xl md:text-3xl font-extrabold">*</h6>
                  </div> -->
                  <h6 class="ml-2 md:ml-4 uppercase text-sm md:text-lg">{{goal.description}}</h6>
                </div>
                <div>
                  <CrossIcon @onClick="removeGoal(goal)" isLink />
                </div>
              </div>
              <div
                class="flex items-center content-center justify-between mb-4 lg:pr-12"
              >
                <div class="flex items-center">
                  <div @click="addGoal" class="cursor-pointer w-12 h-12 md:w-18 md:h-18 bg-primary rounded-full flex items-center justify-center">
                    <PlusIconTwo class="text-white" />
                  </div>
                  <div class="ml-2 md:ml-4">
                    <CustomInputThree
                      v-model="goalToAdd"
                      type="text"
                      name=""
                      placeholder="Add a Goal"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full lg:w-1/2">
              <div class="flex items-center mb-4">
                <div class="w-12 h-12 md:w-18 md:h-18 border border-primary rounded-full flex items-center justify-center">
                  <h6 class="text-xl md:text-3xl font-extrabold">{{completedWorkoutsGym}}</h6>
                </div>
                <h6 class="ml-2 md:ml-4 uppercase text-sm md:text-lg">Total Workouts Complete</h6>
              </div>

              <div class="flex items-center mb-4">
                <div class="w-12 h-12 md:w-18 md:h-18 border border-primary rounded-full flex items-center justify-center">
                  <h6 class="text-xl md:text-3xl font-extrabold">{{completedHoursGym}}</h6>
                </div>
                <h6 class="ml-2 md:ml-4 uppercase text-sm md:text-lg">Hours of Exercise</h6>
              </div>

              <div v-if="completedWorkoutsGym>=100" class="flex items-center mb-4">
                <BadgeIcon100 heightClass="h-15 md:h-20" />
                <h6 class="ml-2 md:ml-4 uppercase text-sm md:text-lg">100 Club</h6>
              </div>

              <div v-if="completedWorkoutsGym>=200" class="flex items-center mb-4">
                <BadgeIcon200 heightClass="h-15 md:h-20" />
                <h6 class="ml-2 md:ml-4 uppercase text-sm md:text-lg">200 Club</h6>
              </div>

              <div v-if="completedWorkoutsGym>=300" class="flex items-center mb-4">
                <BadgeIcon300 heightClass="h-15 md:h-20" />
                <h6 class="ml-2 md:ml-4 uppercase text-sm md:text-lg">300 Club</h6>
              </div>

              <div v-if="completedWorkoutsGym>=400" class="flex items-center mb-4">
                <BadgeIcon400 heightClass="h-15 md:h-20" />
                <h6 class="ml-2 md:ml-4 uppercase text-sm md:text-lg">400 Club</h6>
              </div>

              <div v-if="completedWorkoutsGym>=500" class="flex items-center mb-4">
                <BadgeIcon500 heightClass="h-15 md:h-20" />
                <h6 class="ml-2 md:ml-4 uppercase text-sm md:text-lg">500 Club</h6>
              </div>

            </div>

          </div>

          <!-- tab -->
          <div @click="toggleTab(accordianData[1])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianData[1].name}}</p>
            <PlusIconTwo v-if="!accordianData[1].showing" isLink />
            <div v-if="accordianData[1].showing">
              <MinusIconTwo v-if="!profileLoading" />
              <LoadingIconTwo v-if="profileLoading" />
            </div>
          </div>
          <div v-if="accordianData[1].showing" class="py-4 px-4">
            <div v-if="!showProfileForm" class="w-full flex flex-wrap">
              <div class="w-full">
                <p class="cursor-pointer hover:text-black" @click="showProfileForm=true">Edit</p>
              </div>
              <div>
                <h6 class="font-extrabold">{{this.$store.getters.user.name}} {{this.$store.getters.user.surname}}</h6>
                <h6 class="font-extrabold">{{this.$store.getters.user.email}}</h6>
                <h6 class="font-extrabold">{{this.$store.getters.user.phone}}</h6>
                <div v-if="userUpdateFailed" class="w-full">
                  <h3 class="text-xl md:text-3xl text-red-500">Update Failed!</h3>
                </div>
              </div>
            </div>
            <div v-if="showProfileForm" class="w-full flex flex-wrap">
              <div class="w-full">
                <p class="cursor-pointer hover:text-black" @click="showProfileForm=false">Cancel</p>
              </div>
              <div v-if="!userUpdateFailed" class="">
                <ProfileEditForm @onSubmit="updateUser" />
              </div>
            </div>
          </div>

          <!-- tab -->
          <div @click="toggleTab(accordianData[2])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianData[2].name}}</p>
            <PlusIconTwo v-if="!accordianData[2].showing" isLink />
            <div v-if="accordianData[2].showing">
              <MinusIconTwo v-if="!profileLoading" />
              <LoadingIconTwo v-if="profileLoading" />
            </div>
          </div>
          <div v-if="accordianData[2].showing" class="flex flex-wrap py-4 px-4">

            <div
              v-for="(tag, index) in tagsAssigned"
              :key="index+'assigned'"
              class="w-full md:w-1/3 xl:w-1/4 flex items-center mt-2"
            >
              <MinusIconTwo @onClick="removeTag(tag)" heightClass="h-6" isLink />
              <p class="ml-4 text-xs uppercase tracking-widest">{{tag.name}}</p>
            </div>

            <div
              v-for="(tag, index) in tagsUnassigned"
              :key="index+'unassigned'"
              class="w-full md:w-1/3 xl:w-1/4 flex items-center mt-2"
            >
              <PlusIconTwo @onClick="addTag(tag)" heightClass="h-6" isLink />
              <p class="ml-4 text-xs uppercase tracking-widest">{{tag.name}}</p>
            </div>

          </div>

          <!-- tab -->
          <div @click="goToHowTo" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianData[3].name}}</p>
            <PlusIconTwo v-if="!accordianData[3].showing" isLink />
          </div>

          <!-- tab -->
          <div @click="goToSignup" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianData[4].name}}</p>
            <PlusIconTwo v-if="!accordianData[4].showing" isLink />
          </div>

          <!-- tab -->
          <div @click="manageStripeAccount" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianData[5].name}}</p>
            <PlusIconTwo v-if="!accordianData[5].showing" isLink />
          </div>

        </div>
      </div>
    </div>

    <!-- Hub -->
    <div v-if="navLinks[1].active" class="text-primary">
      <div class="p-8 md:px-40 md:py-24">
        <div class="w-full">
          <h1 class="text-3xl md:text-6xl font-black leading-none">Anywhere</h1>
          <h3 class="text-base md:text-3xl text-kitchen font-extrabold">anytime.</h3>
        </div>
        <div class="w-full border-t border-primary my-4 pt-8 pb-4 flex">
          <CustomButton
            @onClick="goToPage('/gym')"
            textColor="text-gym"
            borderColor="border-gym"
            hoverTextColor="hover:text-gym-dark"
            hoverBorderColor="hover:border-gym-dark"
            class="mr-2"
          >
            Legends Gym
          </CustomButton>
          <CustomButton
            @onClick="goToPage('/academy')"
            textColor="text-academy"
            borderColor="border-academy"
            hoverTextColor="hover:text-academy-dark"
            hoverBorderColor="hover:border-academy-dark"
            class="ml-2"
          >
            Academy
          </CustomButton>
        </div>

        <div class="w-full mt-4 border-primary border-b">

          <!-- first tab -->
          <div @click="toggleTabHub(accordianDataHub[0])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataHub[0].name}}</p>
            <PlusIconTwo v-if="!accordianDataHub[0].showing" isLink />
            <div v-if="accordianDataHub[0].showing">
              <MinusIconTwo v-if="!profileLoading" />
              <LoadingIconTwo v-if="profileLoading" />
            </div>
          </div>
          <div v-if="accordianDataHub[0].showing" class="py-4 px-4 flex flex-wrap">
            <div class="w-full flex flex-wrap">
              <div
                v-for="(workout, index) in savedList" :key="index"
                class="w-1/2 md:w-1/2 xl:w-1/4 px-2 md:px-4 py-2 md:py-6"
              >
                <CardThree
                  v-if="workout.area!=='KITCHEN'"
                  :title="workout.title"
                  :subTitle="workout.categoryTitle"
                  firstTitle="Time"
                  :firstValue="workout.time"
                  secondTitle="Effort"
                  :secondValue="workout.effort"
                  :backgroundColor="workout.backgroundColor"
                  :backgroundHoverColor="workout.backgroundHoverColor"
                  :imagePath="workout.imagePath"
                  @onClick="goToWorkout(workout, workout.area)"
                  @onCrossClick="removeSaved(workout.listId)"
                  showCross
                />

                <CardThree
                  v-if="workout.area==='KITCHEN'"
                  :title="workout.title"
                  :subTitle="workout.categoryTitle"
                  firstTitle="Time"
                  :firstValue="workout.time"
                  secondTitle="Calories"
                  :secondValue="workout.kcal"
                  :secondValueIsRating="false"
                  :backgroundColor="workout.backgroundColor"
                  :backgroundHoverColor="workout.backgroundHoverColor"
                  :imagePath="workout.imagePath"
                  @onClick="goToWorkout(workout, workout.area)"
                  @onCrossClick="removeSaved(workout.listId)"
                  showCross
                />
              </div>

              <CustomButton
                @onClick="getSavedList('full')"
                textColor="text-primary"
                borderColor="border-primary"
                hoverTextColor="hover:text-kitchen-dark"
                hoverBorderColor="hover:border-kitchen-dark"
                class="mt-4 md:mt-0 mb-4"
              >
                View All
              </CustomButton>
            </div>
          </div>

          <!-- second tab -->
          <div @click="toggleTabHub(accordianDataHub[1])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataHub[1].name}}</p>
            <PlusIconTwo v-if="!accordianDataHub[1].showing" isLink />
            <div v-if="accordianDataHub[1].showing">
              <MinusIconTwo v-if="!profileLoading" />
              <LoadingIconTwo v-if="profileLoading" />
            </div>
          </div>
          <div v-if="accordianDataHub[1].showing" class="py-4 px-4 flex flex-wrap">
            <div class="w-full flex flex-wrap">
              <div
                v-for="(workout, index) in recommendedList" :key="index"
                class="w-1/2 md:w-1/2 xl:w-1/4 px-2 md:px-4 py-2 md:py-6"
              >
                <CardThree
                  v-if="workout.area!=='KITCHEN'"
                  :title="workout.title"
                  :subTitle="workout.categoryTitle"
                  firstTitle="Time"
                  :firstValue="workout.time"
                  secondTitle="Effort"
                  :secondValue="workout.effort"
                  :backgroundColor="workout.backgroundColor"
                  :backgroundHoverColor="workout.backgroundHoverColor"
                  :imagePath="workout.imagePath"
                  @onClick="goToWorkout(workout, workout.area)"
                />

                <CardThree
                  v-if="workout.area==='KITCHEN'"
                  :title="workout.title"
                  :subTitle="workout.categoryTitle"
                  firstTitle="Time"
                  :firstValue="workout.time"
                  secondTitle="Calories"
                  :secondValue="workout.kcal"
                  :secondValueIsRating="false"
                  :backgroundColor="workout.backgroundColor"
                  :backgroundHoverColor="workout.backgroundHoverColor"
                  :imagePath="workout.imagePath"
                  @onClick="goToWorkout(workout, workout.area)"
                />
              </div>

              <CustomButton
                @onClick="getRecommended('full')"
                textColor="text-primary"
                borderColor="border-primary"
                hoverTextColor="hover:text-kitchen-dark"
                hoverBorderColor="hover:border-kitchen-dark"
                class="mt-4 md:mt-0 mb-4"
              >
                View All
              </CustomButton>

            </div>
          </div>

          <!-- third tab -->
          <div @click="toggleTabHub(accordianDataHub[2])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataHub[2].name}}</p>
            <PlusIconTwo v-if="!accordianDataHub[2].showing" isLink />
            <div v-if="accordianDataHub[2].showing">
              <MinusIconTwo v-if="!profileLoading" />
              <LoadingIconTwo v-if="profileLoading" />
            </div>
          </div>
          <div v-if="accordianDataHub[2].showing" class="py-4 px-4 flex flex-wrap">
            <div class="w-full flex flex-wrap">
              <div
                v-for="(workout, index) in regularList" :key="index"
                class="w-1/2 md:w-1/2 xl:w-1/4 px-2 md:px-4 py-2 md:py-6"
              >
                <CardThree
                  v-if="workout.area!=='KITCHEN'"
                  :title="workout.title"
                  :subTitle="workout.categoryTitle"
                  firstTitle="Time"
                  :firstValue="workout.time"
                  secondTitle="Effort"
                  :secondValue="workout.effort"
                  :backgroundColor="workout.backgroundColor"
                  :backgroundHoverColor="workout.backgroundHoverColor"
                  :imagePath="workout.imagePath"
                  @onClick="goToWorkout(workout, workout.area)"
                  @onCrossClick="removeCompleted(workout)"
                  showCross
                />

                <CardThree
                  v-if="workout.area==='KITCHEN'"
                  :title="workout.title"
                  :subTitle="workout.categoryTitle"
                  firstTitle="Time"
                  :firstValue="workout.time"
                  secondTitle="Calories"
                  :secondValue="workout.kcal"
                  :secondValueIsRating="false"
                  :backgroundColor="workout.backgroundColor"
                  :backgroundHoverColor="workout.backgroundHoverColor"
                  :imagePath="workout.imagePath"
                  @onClick="goToWorkout(workout, workout.area)"
                  @onCrossClick="removeCompleted(workout)"
                  showCross
                />
              </div>
              <CustomButton
                @onClick="getComplete('full')"
                textColor="text-primary"
                borderColor="border-primary"
                hoverTextColor="hover:text-kitchen-dark"
                hoverBorderColor="hover:border-kitchen-dark"
                class="mt-4 md:mt-0 mb-4"
              >
                View All
              </CustomButton>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Week -->
    <div v-if="navLinks[2].active&&!showDay" class="text-primary">
      <div class="p-8 md:px-40 md:py-24">

        <div class="w-full">
          <h1 class="text-3xl md:text-6xl font-black leading-none">Schedule</h1>
          <h3 class="text-base md:text-3xl text-kitchen font-extrabold">It's a date.</h3>
        </div>

        <div class="w-full mt-4 border-primary border-b">

          <!-- tab -->
          <div @click="toggleTabWeek(accordianDataWeek[0])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataWeek[0].name}}</p>
            <PlusIconTwo v-if="!accordianDataWeek[0].showing" isLink />
            <MinusIconTwo v-if="accordianDataWeek[0].showing" isLink />
          </div>
          <div v-if="accordianDataWeek[0].showing" class="py-4 px-4 flex flex-wrap">
            <div @click="openDayEpt('monday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3 border-t">
              <p class="text-xs uppercase tracking-widest">EPT Daily Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
            <div @click="openDayUser('monday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3">
              <p class="text-xs uppercase tracking-widest">DIY Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
          </div>

          <div @click="toggleTabWeek(accordianDataWeek[1])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataWeek[1].name}}</p>
            <PlusIconTwo v-if="!accordianDataWeek[1].showing" isLink />
            <MinusIconTwo v-if="accordianDataWeek[1].showing" isLink />
          </div>
          <div v-if="accordianDataWeek[1].showing" class="py-4 px-4 flex flex-wrap">
            <div @click="openDayEpt('tuesday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3 border-t">
              <p class="text-xs uppercase tracking-widest">EPT Daily Planner</p>
              <PlusIconTwo heightClass="h-4" />
            </div>
            <div @click="openDayUser('tuesday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3">
              <p class="text-xs uppercase tracking-widest">DIY Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
          </div>

          <div @click="toggleTabWeek(accordianDataWeek[2])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataWeek[2].name}}</p>
            <PlusIconTwo v-if="!accordianDataWeek[2].showing" isLink />
            <MinusIconTwo v-if="accordianDataWeek[2].showing" isLink />
          </div>
          <div v-if="accordianDataWeek[2].showing" class="py-4 px-4 flex flex-wrap">
            <div @click="openDayEpt('wednesday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3 border-t">
              <p class="text-xs uppercase tracking-widest">EPT Daily Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
            <div @click="openDayUser('wednesday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3">
              <p class="text-xs uppercase tracking-widest">DIY Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
          </div>

          <div @click="toggleTabWeek(accordianDataWeek[3])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataWeek[3].name}}</p>
            <PlusIconTwo v-if="!accordianDataWeek[3].showing" isLink />
            <MinusIconTwo v-if="accordianDataWeek[3].showing" isLink />
          </div>
          <div v-if="accordianDataWeek[3].showing" class="py-4 px-4 flex flex-wrap">
            <div @click="openDayEpt('thursday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3 border-t">
              <p class="text-xs uppercase tracking-widest">EPT Daily Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
            <div @click="openDayUser('thursday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3">
              <p class="text-xs uppercase tracking-widest">DIY Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
          </div>

          <div @click="toggleTabWeek(accordianDataWeek[4])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataWeek[4].name}}</p>
            <PlusIconTwo v-if="!accordianDataWeek[4].showing" isLink />
            <MinusIconTwo v-if="accordianDataWeek[4].showing" isLink />
          </div>
          <div v-if="accordianDataWeek[4].showing" class="py-4 px-4 flex flex-wrap">
            <div @click="openDayEpt('friday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3 border-t">
              <p class="text-xs uppercase tracking-widest">EPT Daily Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
            <div @click="openDayUser('friday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3">
              <p class="text-xs uppercase tracking-widest">DIY Planner</p>
              <PlusIconTwo heightClass="h-4" />
            </div>
          </div>

          <div @click="toggleTabWeek(accordianDataWeek[5])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataWeek[5].name}}</p>
            <PlusIconTwo v-if="!accordianDataWeek[5].showing" isLink />
            <MinusIconTwo v-if="accordianDataWeek[5].showing" isLink />
          </div>
          <div v-if="accordianDataWeek[5].showing" class="py-4 px-4 flex flex-wrap">
            <div @click="openDayEpt('saturday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3 border-t">
              <p class="text-xs uppercase tracking-widest">EPT Daily Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
            <div @click="openDayUser('saturday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3">
              <p class="text-xs uppercase tracking-widest">DIY Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
          </div>

          <div @click="toggleTabWeek(accordianDataWeek[6])" class="cursor-pointer select-none py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{accordianDataWeek[6].name}}</p>
            <PlusIconTwo v-if="!accordianDataWeek[6].showing" isLink />
            <MinusIconTwo v-if="accordianDataWeek[6].showing" isLink />
          </div>
          <div v-if="accordianDataWeek[6].showing" class="py-4 px-4 flex flex-wrap">
            <div @click="openDayEpt('sunday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3 border-t">
              <p class="text-xs uppercase tracking-widest">EPT Daily Planner</p>
              <PlusIconTwo heightClass="h-4" />
            </div>
            <div @click="openDayUser('sunday')" class="cursor-pointer w-full flex justify-between items-center border-b border-kitchen py-3">
              <p class="text-xs uppercase tracking-widest">DIY Planner</p>
              <PlusIconTwo  heightClass="h-4" />
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Day -->
    <div v-if="navLinks[2].active&&showDay" class="text-primary">
      <div class="p-8 md:px-40 md:py-24">
        <div class="w-full">
          <h1 class="font-black leading-none capitalize">{{dayTitle}}</h1>
          <h3 class="text-kitchen font-extrabold leading-nne">{{daySecondTitle}}</h3>
        </div>

        <div class="w-full mt-4 border-primary border-b">

          <!-- tab -->
          <div class="py-4 px-4 border-t border-primary flex justify-between">
            <p class="uppercase tracking-widest">{{dayThirdTitle}}</p>
            <MinusIconTwo @onClick="backToWeek()" isLink />
          </div>
          <div class="py-4 px-4 flex flex-wrap">

            <div
              v-if="whichDay==='search'"
              class="w-full flex"
            >
              <div class="w-1/2 mr-4">
                <CustomInputThree
                  v-model="searchQuery"
                  type="text"
                  name=""
                  placeholder="Search for a Workout or Recipe"
                />
              </div>
              <div class="w-1/2 ml-4">
                <CustomButton
                    @onClick="search()"
                    textColor="text-primary"
                    borderColor="border-primary"
                    hoverTextColor="hover:text-kitchen-dark"
                    hoverBorderColor="hover:border-kitchen-dark"
                    class="mr-2"
                  >
                    Search
                  </CustomButton>
              </div>
            </div>

            <div class="w-full flex flex-wrap">
              <div
                v-for="(workout, index) in dayWorkouts" :key="index+'day'"
                class="w-full md:w-1/2 xl:w-1/4 px-2 md:px-4 py-2 md:py-6"
              >
                <CardThree
                  v-if="workout.area!=='KITCHEN'"
                  :title="workout.title"
                  :subTitle="workout.categoryTitle"
                  firstTitle="Time"
                  :firstValue="workout.time"
                  secondTitle="Effort"
                  :secondValue="workout.effort"
                  :backgroundColor="workout.backgroundColor"
                  :backgroundHoverColor="workout.backgroundHoverColor"
                  :imagePath="workout.imagePath"
                  @onClick="dayCardClick(workout, workout.area, dayTitle)"
                  @onCrossClick="removeFromDay(workout.listId)"
                  :showCross="whichDay==='user'"
                />
                <CardThree
                  v-if="workout.area==='KITCHEN'"
                  :title="workout.title"
                  :subTitle="workout.categoryTitle"
                  firstTitle="Time"
                  :firstValue="workout.time"
                  secondTitle="Calories"
                  :secondValue="workout.kcal"
                  :secondValueIsRating="false"
                  :backgroundColor="workout.backgroundColor"
                  :backgroundHoverColor="workout.backgroundHoverColor"
                  :imagePath="workout.imagePath"
                  @onClick="dayCardClick(workout, workout.area, dayTitle)"
                  @onCrossClick="removeFromDay(workout.listId)"
                  :showCross="whichDay==='user'"
                />
              </div>

              <div
                v-if="whichDay==='user'"
                class="w-full md:w-1/2 xl:w-1/4 px-2 md:px-4 py-2 md:py-6"
              >
                  <div
                    @click="openSearch(dayTitle)"
                    class="flex items-center justify-center cursor-pointer w-full h-72 md:h-80 border text-kitchen border-kitchen hover:border-primary hover:text-primary rounded-xl"
                  >
                    <PlusIconTwo heightClass="h-10" isLink />
                  </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import NavBar from '@/components/NavBar.vue'
import PlusIconTwo from '@/components/icons/PlusIconTwo.vue'
import MinusIconTwo from '@/components/icons/MinusIconTwo.vue'
import CrossIcon from '@/components/icons/CrossIcon.vue'
import CustomButton from '@/components/elements/CustomButton.vue'
import CustomInputThree from '@/components/elements/CustomInputThree.vue'
import ProfileEditForm from '@/components/ProfileEditForm.vue'
import CardThree from '@/components/CardThree.vue'
import GoalIcon from '@/components/icons/GoalIcon.vue'
import BadgeIcon100 from '@/components/icons/BadgeIcon100.vue'
import BadgeIcon200 from '@/components/icons/BadgeIcon200.vue'
import BadgeIcon300 from '@/components/icons/BadgeIcon300.vue'
import BadgeIcon400 from '@/components/icons/BadgeIcon400.vue'
import BadgeIcon500 from '@/components/icons/BadgeIcon500.vue'
import LoadingIconTwo from '@/components/icons/LoadingIconTwo.vue'

export default {
  name: 'profile',
  components: {
    NavBar,
    PlusIconTwo,
    MinusIconTwo,
    CrossIcon,
    CustomButton,
    CustomInputThree,
    ProfileEditForm,
    CardThree,
    GoalIcon,
    BadgeIcon100,
    BadgeIcon200,
    BadgeIcon300,
    BadgeIcon400,
    BadgeIcon500,
    LoadingIconTwo
  },
  data () {
    return {
      profileLoading: true,
      getGoalsFirst: false,
      getCompleteTotalsFirst: false,
      getTagsAssignedFirst: false,
      getTagsUnassignedFirst: false,
      getSavedListFirst: false,
      getRecommendedFirst: false,
      getgetCompleteFirst: false,
      getGoalsLoaded: true,
      getCompleteTotalsLoaded: true,
      getCompleteLoaded: true,
      getSavedListLoaded: true,
      getEptWeekLoaded: true,
      getUserWeekLoaded: true,
      getTagsAssignedLoaded: true,
      getTagsUnassignedLoaded: true,
      getRecommendedLoaded: true,
      recommendedList: [],
      tagsAssigned: [],
      tagsUnassigned: [],
      searchQuery: '',
      whichDay: '',
      showDay: false,
      showProfileForm: false,
      userUpdateFailed: false,
      goalToAdd: '',
      completedWorkoutsGym: 0,
      completedHoursGym: 0,
      goals: [],
      savedList: [],
      regularList: [],
      eptWeek: {
        monday: {
          workouts: []
        },
        tuesday: {
          workouts: []
        },
        wednesday: {
          workouts: []
        },
        thursday: {
          workouts: []
        },
        friday: {
          workouts: []
        },
        saturday: {
          workouts: []
        },
        sunday: {
          workouts: []
        }
      },
      userWeek: {
        monday: {
          workouts: []
        },
        tuesday: {
          workouts: []
        },
        wednesday: {
          workouts: []
        },
        thursday: {
          workouts: []
        },
        friday: {
          workouts: []
        },
        saturday: {
          workouts: []
        },
        sunday: {
          workouts: []
        }
      },
      dayWorkouts: [],
      accordianData: [
        {
          name: 'Goals & Awards',
          showing: false
        },
        {
          name: 'Profile',
          showing: false
        },
        {
          name: 'Preferences',
          showing: false
        },
        // {
        //   name: 'Refer a Friend',
        //   showing: false
        // },
        {
          name: 'How to get started',
          showing: false
        },
        {
          name: 'Refer a friend',
          showing: false
        },
        {
          name: 'Manage My Account',
          showing: false
        }
      ],
      accordianDataHub: [
        {
          name: 'Saved',
          showing: false
        },
        {
          name: 'Recommended',
          showing: false
        },
        {
          name: 'Regular',
          showing: false
        }
      ],
      accordianDataWeek: [
        {
          name: 'Monday',
          showing: false
        },
        {
          name: 'Tuesday',
          showing: false
        },
        {
          name: 'Wednesday',
          showing: false
        },
        {
          name: 'Thursday',
          showing: false
        },
        {
          name: 'Friday',
          showing: false
        },
        {
          name: 'Saturday',
          showing: false
        },
        {
          name: 'Sunday',
          showing: false
        }
      ],
      navLinks: [
        {
          title: 'Profile',
          active: false
        },
        {
          title: 'Hub',
          active: false
        },
        {
          title: 'Week',
          active: false
        }
      ]
    }
  },
  computed: {
  },
  methods: {
    checkStopLoading () {
      if (
        this.getGoalsLoaded &&
        this.getCompleteTotalsLoaded &&
        this.getCompleteLoaded &&
        this.getSavedListLoaded &&
        this.getEptWeekLoaded &&
        this.getUserWeekLoaded &&
        this.getTagsAssignedLoaded &&
        this.getTagsUnassignedLoaded &&
        this.getRecommendedLoaded
      ) {
        this.$store.dispatch('setShowLoading', false)
        this.profileLoading = false
      }
    },
    removeTag (tag) {
      var route = ''
      if (tag.area === 'GYM') route = 'remove-tag-gym'
      else if (tag.area === 'ACADEMY') route = 'remove-tag-acadamy'
      else if (tag.area === 'KITCHEN') route = 'remove-tag-kitchen'
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('tagId', tag.id)
      axios.post(process.env.VUE_APP_ROOT_API + '/' + route, updater, authorization).then(response => {
        this.getTagsAssigned()
        this.getTagsUnassigned()
        this.getRecommended()
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    addTag (tag) {
      var route = ''
      if (tag.area === 'GYM') route = 'add-tag-gym'
      else if (tag.area === 'ACADEMY') route = 'add-tag-acadamy'
      else if (tag.area === 'KITCHEN') route = 'add-tag-kitchen'

      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('tagId', tag.id)
      axios.post(process.env.VUE_APP_ROOT_API + '/' + route, updater, authorization).then(response => {
        this.getTagsAssigned()
        this.getTagsUnassigned()
        this.getRecommended()
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    removeFromDay (listId) {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('listId', listId)
      axios.post(process.env.VUE_APP_ROOT_API + '/remove-from-day', updater, authorization).then(response => {
        this.getUserWeek()
        this.backToWeek()
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    removeSaved (listId) {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('listId', listId)
      axios.post(process.env.VUE_APP_ROOT_API + '/remove-saved', updater, authorization).then(response => {
        this.getSavedList()
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    removeCompleted (workout) {
      var whichRoute = ''
      if (workout.area === 'GYM') whichRoute = 'hide-completed-gym'
      else if (workout.area === 'ACADEMY') whichRoute = 'hide-completed-acadamy'
      else if (workout.area === 'KITCHEN') whichRoute = 'hide-completed-kitchen'
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('listId', workout.listId)
      axios.post(process.env.VUE_APP_ROOT_API + '/' + whichRoute, updater, authorization).then(response => {
        this.getComplete()
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    search () {
      this.dayWorkouts = []

      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('searchQuery', this.searchQuery)
      axios.post(process.env.VUE_APP_ROOT_API + '/search_all', updater, authorization).then(response => {
        console.log(response.data)
        for (var i = 0; i < response.data.length; i++) {
          var backgroundColor = ''
          var backgroundHoverColor = ''

          if (response.data[i].area === 'GYM') {
            backgroundColor = 'bg-gym'
            backgroundHoverColor = 'bg-gym-dark'
          } else if (response.data[i].area === 'ACADEMY') {
            backgroundColor = 'bg-academy'
            backgroundHoverColor = 'bg-academy-dark'
          } else if (response.data[i].area === 'KITCHEN') {
            backgroundColor = 'bg-kitchen'
            backgroundHoverColor = 'bg-kitchen-dark'
          }

          this.dayWorkouts.push(response.data[i])
          this.dayWorkouts[i].categoryId = response.data[i].workout_category.id
          this.dayWorkouts[i].categoryTitle = response.data[i].workout_category.title
          this.dayWorkouts[i].backgroundColor = backgroundColor
          this.dayWorkouts[i].backgroundHoverColor = backgroundHoverColor
          this.dayWorkouts[i].area = response.data[i].area

          if (response.data[i].image) this.dayWorkouts[i].imagePath = response.data[i].image.path
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    backToWeek () {
      this.showDay = false
    },
    openSearch (day) {
      this.showDay = true
      this.dayTitle = day
      this.daySecondTitle = 'add to ' + day + '.'
      this.dayThirdTitle = 'Search'
      this.whichDay = 'search'

      this.dayWorkouts = []
    },
    openDayEpt (day) {
      this.showDay = true
      this.dayTitle = day
      this.daySecondTitle = 'by us, for you.'
      this.dayThirdTitle = day
      this.whichDay = 'ept'

      if (day === 'monday') this.dayWorkouts = this.eptWeek.monday.workouts
      else if (day === 'tuesday') this.dayWorkouts = this.eptWeek.tuesday.workouts
      else if (day === 'wednesday') this.dayWorkouts = this.eptWeek.wednesday.workouts
      else if (day === 'thursday') this.dayWorkouts = this.eptWeek.thursday.workouts
      else if (day === 'friday') this.dayWorkouts = this.eptWeek.friday.workouts
      else if (day === 'saturday') this.dayWorkouts = this.eptWeek.saturday.workouts
      else if (day === 'sunday') this.dayWorkouts = this.eptWeek.sunday.workouts
    },
    openDayUser (day) {
      this.showDay = true
      this.dayTitle = day
      this.daySecondTitle = 'your favourites.'
      this.dayThirdTitle = day
      this.whichDay = 'user'

      if (day === 'monday') this.dayWorkouts = this.userWeek.monday.workouts
      else if (day === 'tuesday') this.dayWorkouts = this.userWeek.tuesday.workouts
      else if (day === 'wednesday') this.dayWorkouts = this.userWeek.wednesday.workouts
      else if (day === 'thursday') this.dayWorkouts = this.userWeek.thursday.workouts
      else if (day === 'friday') this.dayWorkouts = this.userWeek.friday.workouts
      else if (day === 'saturday') this.dayWorkouts = this.userWeek.saturday.workouts
      else if (day === 'sunday') this.dayWorkouts = this.userWeek.sunday.workouts
    },
    dayCardClick (workout, area, day) {
      if (this.whichDay === 'search') this.addToDay(workout, day)
      else this.goToWorkout(workout, area)
    },
    addToDay (workout, day) {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('day', day)
      updater.append('workout_id', workout.id)
      updater.append('area', workout.area)
      axios.post(process.env.VUE_APP_ROOT_API + '/add-to-day', updater, authorization).then(response => {
        this.getUserWeek()
        this.backToWeek()
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    goToWorkout (workout, area) {
      if (area === 'GYM') {
        this.$router.push('/gymworkout/' + workout.categoryId + '/' + workout.id)
      } else if (area === 'ACADEMY') {
        this.$router.push('/academyworkout/' + workout.categoryId + '/' + workout.id)
      } else if (area === 'KITCHEN') {
        this.$router.push('/kitchenworkout/' + workout.categoryId + '/' + workout.id)
      }
    },
    updateUser (obj) {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('name', obj.name)
      updater.append('surname', obj.surname)
      updater.append('phone', obj.phone)
      axios.post(process.env.VUE_APP_ROOT_API + '/update_user', updater, authorization).then(response => {
        if (response.data === 'FAILED') this.userUpdateFailed = true
        else {
          this.showProfileForm = false
          this.$store.dispatch('setUser', response.data)
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    removeGoal (goal) {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('id', goal.id)
      axios.post(process.env.VUE_APP_ROOT_API + '/remove-goal', updater, authorization).then(response => {
        this.getGoals()
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    addGoal () {
      if (this.goalToAdd) {
        const authorization = {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        }
        const updater = new URLSearchParams()
        updater.append('uuid', this.$store.getters.user.uuid)
        updater.append('description', this.goalToAdd)
        axios.post(process.env.VUE_APP_ROOT_API + '/add-goal', updater, authorization).then(response => {
          this.goalToAdd = ''
          this.getGoals()
        }).catch(error => {
          this.$store.dispatch('setTokenExpired', true)
          console.log('Error 1', error)
        })
      }
    },
    getGoals () {
      this.getGoalsLoaded = false
      this.profileLoading = true // this.$store.dispatch('setShowLoading', true)

      this.goals = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      axios.post(process.env.VUE_APP_ROOT_API + '/goals', updater, authorization).then(response => {
        this.getGoalsLoaded = true
        this.checkStopLoading()

        this.goals = response.data
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getCompleteTotals () {
      this.getCompleteTotalsLoaded = false
      this.profileLoading = true // this.$store.dispatch('setShowLoading', true)

      this.completedWorkoutsGym = 0
      this.completedHoursGym = 0
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      axios.post(process.env.VUE_APP_ROOT_API + '/workouts-completed-totals', updater, authorization).then(response => {
        this.getCompleteTotalsLoaded = true
        this.checkStopLoading()

        this.completedWorkoutsGym = response.data.completedWorkoutsGym
        this.completedHoursGym = response.data.completedHoursGym
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getComplete (fullList = 'paginated') {
      this.getCompleteLoaded = false
      this.profileLoading = true // this.$store.dispatch('setShowLoading', true)

      this.regularList = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('fullList', fullList)
      axios.post(process.env.VUE_APP_ROOT_API + '/workouts-completed', updater, authorization).then(response => {
        this.getCompleteLoaded = true
        this.checkStopLoading()

        for (var i = 0; i < response.data.length; i++) {
          var backgroundColor = ''
          var backgroundHoverColor = ''

          if (response.data[i].area === 'GYM') {
            backgroundColor = 'bg-gym'
            backgroundHoverColor = 'bg-gym-dark'
          } else if (response.data[i].area === 'ACADEMY') {
            backgroundColor = 'bg-academy'
            backgroundHoverColor = 'bg-academy-dark'
          } else if (response.data[i].area === 'KITCHEN') {
            backgroundColor = 'bg-kitchen'
            backgroundHoverColor = 'bg-kitchen-dark'
          }

          this.regularList.push(response.data[i].workout)
          this.regularList[i].listId = response.data[i].id
          this.regularList[i].categoryId = response.data[i].workout_category.id
          this.regularList[i].categoryTitle = response.data[i].workout_category.title
          this.regularList[i].backgroundColor = backgroundColor
          this.regularList[i].backgroundHoverColor = backgroundHoverColor
          this.regularList[i].area = response.data[i].area

          if (response.data[i].workout.image) this.regularList[i].imagePath = response.data[i].workout.image.path
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getSavedList (fullList = 'paginated') {
      this.getSavedListLoaded = false
      this.profileLoading = true // this.$store.dispatch('setShowLoading', true)

      this.savedList = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('fullList', fullList)
      axios.post(process.env.VUE_APP_ROOT_API + '/saved-lists', updater, authorization).then(response => {
        this.getSavedListLoaded = true
        this.checkStopLoading()

        if (fullList === 'paginated') response.data = response.data.data
        for (var i = 0; i < response.data.length; i++) {
          var backgroundColor = ''
          var backgroundHoverColor = ''

          if (response.data[i].area === 'GYM') {
            backgroundColor = 'bg-gym'
            backgroundHoverColor = 'bg-gym-dark'
          } else if (response.data[i].area === 'ACADEMY') {
            backgroundColor = 'bg-academy'
            backgroundHoverColor = 'bg-academy-dark'
          } else if (response.data[i].area === 'KITCHEN') {
            backgroundColor = 'bg-kitchen'
            backgroundHoverColor = 'bg-kitchen-dark'
          }

          if (response.data[i].workout) {
            this.savedList.push(response.data[i].workout)
            this.savedList[i].listId = response.data[i].id
            this.savedList[i].categoryId = response.data[i].category.id
            this.savedList[i].categoryTitle = response.data[i].category.title
            this.savedList[i].backgroundColor = backgroundColor
            this.savedList[i].backgroundHoverColor = backgroundHoverColor
            this.savedList[i].area = response.data[i].area

            if (response.data[i].workout.image) this.savedList[i].imagePath = response.data[i].workout.image.path
          }
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getRecommended (fullList = 'paginated') {
      this.getRecommendedLoaded = false
      this.profileLoading = true // this.$store.dispatch('setShowLoading', true)

      this.recommendedList = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('fullList', fullList)
      axios.post(process.env.VUE_APP_ROOT_API + '/workouts-with-user-tags', updater, authorization).then(response => {
        this.getRecommendedLoaded = true
        this.checkStopLoading()

        for (var i = 0; i < response.data.length; i++) {
          var backgroundColor = ''
          var backgroundHoverColor = ''

          if (response.data[i].area === 'GYM') {
            backgroundColor = 'bg-gym'
            backgroundHoverColor = 'bg-gym-dark'
          } else if (response.data[i].area === 'ACADEMY') {
            backgroundColor = 'bg-academy'
            backgroundHoverColor = 'bg-academy-dark'
          } else if (response.data[i].area === 'KITCHEN') {
            backgroundColor = 'bg-kitchen'
            backgroundHoverColor = 'bg-kitchen-dark'
          }

          this.recommendedList.push(response.data[i])
          this.recommendedList[i].categoryId = response.data[i].category.id
          this.recommendedList[i].categoryTitle = response.data[i].category.title
          this.recommendedList[i].backgroundColor = backgroundColor
          this.recommendedList[i].backgroundHoverColor = backgroundHoverColor
          this.recommendedList[i].area = response.data[i].area

          if (response.data[i].image) this.recommendedList[i].imagePath = response.data[i].image.path
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getEptWeek () {
      this.getEptWeekLoaded = false
      this.$store.dispatch('setShowLoading', true)

      this.eptWeek = {}
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      axios.get(process.env.VUE_APP_ROOT_API + '/ept-week', authorization).then(response => {
        this.getEptWeekLoaded = true
        this.checkStopLoading()

        this.eptWeek = {
          monday: {
            workouts: []
          },
          tuesday: {
            workouts: []
          },
          wednesday: {
            workouts: []
          },
          thursday: {
            workouts: []
          },
          friday: {
            workouts: []
          },
          saturday: {
            workouts: []
          },
          sunday: {
            workouts: []
          }
        }

        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].day === 'MONDAY') {
            for (var j = 0; j < response.data[i].workouts.length; j++) {
              this.eptWeek.monday.workouts.push(response.data[i].workouts[j])
              if (response.data[i].workouts[j].image) this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.eptWeek.monday.workouts[this.eptWeek.monday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'TUESDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.eptWeek.tuesday.workouts.push(response.data[i].workouts[j])
              if (response.data[i].workouts[j].image) this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.eptWeek.tuesday.workouts[this.eptWeek.tuesday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'WEDNESDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.eptWeek.wednesday.workouts.push(response.data[i].workouts[j])
              if (response.data[i].workouts[j].image) this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.eptWeek.wednesday.workouts[this.eptWeek.wednesday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'THURSDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.eptWeek.thursday.workouts.push(response.data[i].workouts[j])
              if (response.data[i].workouts[j].image) this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.eptWeek.thursday.workouts[this.eptWeek.thursday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'FRIDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.eptWeek.friday.workouts.push(response.data[i].workouts[j])
              if (response.data[i].workouts[j].image) this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.eptWeek.friday.workouts[this.eptWeek.friday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'SATURDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.eptWeek.saturday.workouts.push(response.data[i].workouts[j])
              if (response.data[i].workouts[j].image) this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.eptWeek.saturday.workouts[this.eptWeek.saturday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'SUNDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.eptWeek.sunday.workouts.push(response.data[i].workouts[j])
              if (response.data[i].workouts[j].image) this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.eptWeek.sunday.workouts[this.eptWeek.sunday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          }
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getUserWeek () {
      this.getUserWeekLoaded = false
      this.$store.dispatch('setShowLoading', true)

      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      axios.post(process.env.VUE_APP_ROOT_API + '/user-week', updater, authorization).then(response => {
        this.getUserWeekLoaded = true
        this.checkStopLoading()

        this.userWeek = {
          monday: {
            workouts: []
          },
          tuesday: {
            workouts: []
          },
          wednesday: {
            workouts: []
          },
          thursday: {
            workouts: []
          },
          friday: {
            workouts: []
          },
          saturday: {
            workouts: []
          },
          sunday: {
            workouts: []
          }
        }

        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].day === 'MONDAY') {
            for (var j = 0; j < response.data[i].workouts.length; j++) {
              this.userWeek.monday.workouts.push(response.data[i].workouts[j])
              this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].listId = response.data[i].id
              if (response.data[i].workouts[j].image) this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.userWeek.monday.workouts[this.userWeek.monday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'TUESDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.userWeek.tuesday.workouts.push(response.data[i].workouts[j])
              this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].listId = response.data[i].id
              if (response.data[i].workouts[j].image) this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.userWeek.tuesday.workouts[this.userWeek.tuesday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'WEDNESDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.userWeek.wednesday.workouts.push(response.data[i].workouts[j])
              this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].listId = response.data[i].id
              if (response.data[i].workouts[j].image) this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.userWeek.wednesday.workouts[this.userWeek.wednesday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'THURSDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.userWeek.thursday.workouts.push(response.data[i].workouts[j])
              this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].listId = response.data[i].id
              if (response.data[i].workouts[j].image) this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.userWeek.thursday.workouts[this.userWeek.thursday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'FRIDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.userWeek.friday.workouts.push(response.data[i].workouts[j])
              this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].listId = response.data[i].id
              if (response.data[i].workouts[j].image) this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.userWeek.friday.workouts[this.userWeek.friday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'SATURDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.userWeek.saturday.workouts.push(response.data[i].workouts[j])
              this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].listId = response.data[i].id
              if (response.data[i].workouts[j].image) this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.userWeek.saturday.workouts[this.userWeek.saturday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          } else if (response.data[i].day === 'SUNDAY') {
            for (j = 0; j < response.data[i].workouts.length; j++) {
              this.userWeek.sunday.workouts.push(response.data[i].workouts[j])
              this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].listId = response.data[i].id
              if (response.data[i].workouts[j].image) this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].imagePath = response.data[i].workouts[j].image.path
              if (response.data[i].workouts[j].area === 'GYM') {
                this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].backgroundColor = 'bg-gym'
                this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].backgroundHoverColor = 'bg-gym-dark'
              } else if (response.data[i].workouts[j].area === 'ACADEMY') {
                this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].backgroundColor = 'bg-academy'
                this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].backgroundHoverColor = 'bg-academy-dark'
              } else if (response.data[i].workouts[j].area === 'KITCHEN') {
                this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].backgroundColor = 'bg-kitchen'
                this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].backgroundHoverColor = 'bg-kitchen-dark'
              }
              this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].categoryId = response.data[i].workouts[j].categories[0].id
              this.userWeek.sunday.workouts[this.userWeek.sunday.workouts.length - 1].categoryTitle = response.data[i].workouts[j].categories[0].title
            }
          }
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getTagsAssigned () {
      this.getTagsAssignedLoaded = false
      this.profileLoading = true // this.$store.dispatch('setShowLoading', true)

      this.tagsAssigned = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      axios.post(process.env.VUE_APP_ROOT_API + '/user-tags-assigned', updater, authorization).then(response => {
        this.getTagsAssignedLoaded = true
        this.checkStopLoading()

        this.tagsAssigned = response.data
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getTagsUnassigned () {
      this.getTagsUnassignedLoaded = false
      this.profileLoading = true // this.$store.dispatch('setShowLoading', true)

      this.tagsUnassigned = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      axios.post(process.env.VUE_APP_ROOT_API + '/user-tags-unassigned', updater, authorization).then(response => {
        this.getTagsUnassignedLoaded = true
        this.checkStopLoading()

        this.tagsUnassigned = response.data
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    goToPage (url) {
      this.$router.push(url)
    },
    toggleTab (whichTab) {
      for (var i = 0; i < this.accordianData.length; i++) {
        if (whichTab.name === this.accordianData[i].name) {
          this.accordianData[i].showing = !this.accordianData[i].showing

          if (whichTab.name === 'Goals & Awards' && this.accordianData[i].showing && !this.getGoalsFirst) {
            this.getGoals()
            this.getGoalsFirst = true
          }

          if (whichTab.name === 'Goals & Awards' && this.accordianData[i].showing && !this.getCompleteTotalsFirst) {
            this.getCompleteTotals()
            this.getCompleteTotalsFirst = true
          }

          if (whichTab.name === 'Preferences' && this.accordianData[i].showing && !this.getTagsAssignedFirst && !this.getTagsUnassignedFirst) {
            this.getTagsAssigned()
            this.getTagsUnassigned()
            this.getTagsAssignedFirst = true
            this.getTagsUnassignedFirst = true
          }
        }
      }
    },
    toggleTabHub (whichTab) {
      for (var i = 0; i < this.accordianDataHub.length; i++) {
        if (whichTab.name === this.accordianDataHub[i].name) this.accordianDataHub[i].showing = !this.accordianDataHub[i].showing

        if (whichTab.name === 'Saved' && this.accordianDataHub[i].showing && !this.getSavedListFirst) {
          this.getSavedList()
          this.getSavedListFirst = true
        }

        if (whichTab.name === 'Recommended' && this.accordianDataHub[i].showing && !this.getRecommendedFirst) {
          this.getRecommended()
          this.getRecommendedFirst = true
        }

        if (whichTab.name === 'Regular' && this.accordianDataHub[i].showing && !this.getCompleteFirst) {
          this.getComplete()
          this.getCompleteFirst = true
        }
      }
    },
    toggleTabWeek (whichTab) {
      for (var i = 0; i < this.accordianDataWeek.length; i++) {
        if (whichTab.name === this.accordianDataWeek[i].name) this.accordianDataWeek[i].showing = !this.accordianDataWeek[i].showing
      }
    },
    updateNav (index) {
      for (var i = 0; i < this.navLinks.length; i++) {
        if (i === index) {
          this.navLinks[i].active = true
          // if (this.navLinks[i].title === 'Profile') {
          //   this.getGoals()
          //   this.getCompleteTotals()
          //   this.getTagsAssigned()
          //   this.getTagsUnassigned()
          // } else if (this.navLinks[i].title === 'Hub') {
          //   this.getComplete()
          //   this.getSavedList()
          //   this.getRecommended()
          // } else if (this.navLinks[i].title === 'Week') {
          //   this.getEptWeek()
          //   this.getUserWeek()
          // }

          if (this.navLinks[i].title === 'Week') {
            this.getEptWeek()
            this.getUserWeek()
          }
        } else this.navLinks[i].active = false
      }
    },
    goToHowTo () {
      this.$router.push('/howto')
    },
    goToSignup () {
      window.open('https://eptlegends.tapfiliate.com/signup/', '_blank')
    },
    manageStripeAccount () {
      this.$store.dispatch('setShowLoading', true)
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('searchQuery', this.searchQuery)
      updater.append('uuid', this.$store.getters.user.uuid)
      axios.post(process.env.VUE_APP_ROOT_API + '/manage_stripe_url', updater, authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)

        if (response.data !== 'NO_MATCH') {
          window.open(response.data, '_blank')
        } else {
          console.log('USER NOT FOUND')
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.updateNav(parseInt(to.params.tab))
    next()
  },
  mounted () {
    // if (parseInt(this.$route.params.tab) === 0) {
    //   this.getGoals()
    //   this.getCompleteTotals()
    //   this.getTagsAssigned()
    //   this.getTagsUnassigned()
    // } else if (parseInt(this.$route.params.tab) === 1) {
    //   this.getComplete()
    //   this.getSavedList()
    //   this.getRecommended()
    // } else if (parseInt(this.$route.params.tab) === 2) {
    //   this.getEptWeek()
    //   this.getUserWeek()
    // }
    this.navLinks[parseInt(this.$route.params.tab)].active = true
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
