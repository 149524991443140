<template>
  <div
    class="z-40 fixed w-full top-0 left-0 flex"
  >
    <div class="w-full flex justify-between m-4">
      <div class="flex items-center">
        <div class="z-50 flex items-center">
          <SmileIcon @onClick="goToLogin" heightClass="h-8" :isLink="!this.$store.getters.token" />
          <h6 v-if="!this.$store.getters.token" @click="goToLogin" class="cursor-pointer ml-4 capitalize font-extrabold leading-none">Login</h6>
          <h6 v-if="this.$store.getters.token" class="ml-4 capitalize font-extrabold leading-none">{{this.$store.getters.user.name}} {{this.$store.getters.user.surname}}</h6>
        </div>
      </div>
      <Logo @onClick="logoClick" class="z-50" heightClass="h-8" isLink />
    </div>

    <transition name="fade">
      <div
        v-if="showingPage"
        class="absolute z-40 w-full h-screen text-white flex flex-wrap content-between"
        :class="[
          backgroundColor
        ]"
      >
        <div class="w-full h-12 md:h-40">
        </div>
        <div class="w-full flex flex-wrap justify-center items-center">
          <div
            class="w-full flex flex-wrap justify-start px-12 md:px-24"
          >
            <Logo heightClass="h-12" />
            <div
              @click="showingPage=false"
              class="w-full mt-4"
            >
              <template
                v-for="route in routesList"
              >
                <router-link
                  class="no-underline"
                  :key="route.path"
                  :to="route.path"
                >
                  <NavLinkHeading :word="route.name" :dotColor="route.dotColor" />
                </router-link>
                <br :key="route.path+'br'" />
              </template>
            </div>
            <div v-if="this.$store.getters.token" class="w-full md:w-72 mt-10">
              <CustomButton @onClick="logoutUser">Logout</CustomButton>
            </div>
          </div>
        </div>

        <div class="w-full">
          <Footer />
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
// import axios from 'axios'
import Logo from '@/components/icons/Logo.vue'
import SmileIcon from '@/components/icons/SmileIcon.vue'
import NavLinkHeading from '@/components/elements/NavLinkHeading.vue'
import CustomButton from '@/components/elements/CustomButton.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'NavPage',
  components: {
    Logo,
    SmileIcon,
    NavLinkHeading,
    CustomButton,
    Footer
  },
  props: {
    iconColor: {
      type: String,
      default: 'text-white'
    },
    backgroundColor: {
      type: String,
      default: 'bg-black'
    }
  },
  data () {
    return {
      showingPage: false
    }
  },
  computed: {
    routesList () {
      return this.$store.state.navLinks
    }
  },
  methods: {
    goToLogin () {
      // if (!this.$store.getters.token) this.$router.push('/profile/0')
      if (!this.$store.getters.token) window.location = '/profile/0'
    },
    logoutUser () {
      this.$store.dispatch('logoutUser', '')
      this.showingPage = false
      window.location = '/'
    },
    logoClick () {
      if (this.$store.getters.token) this.showingPage = !this.showingPage
      else window.location = '/'
    }
  }
}

</script>

<style lang="scss">

</style>
