<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 1182 1182"
    @click="$emit('onClick')"
  >
    <g id="Play" transform="matrix(37.0333,5.02118e-30,-4.56471e-31,37.0333,-8519.64,-10315.8)">
        <path d="M236,284.2C236,283.265 237.064,282.69 237.894,283.177L255.404,293.479C256.198,293.945 256.198,295.055 255.404,295.522C252.694,297.117 250.661,298.312 249.306,299.11C246.77,300.601 242.966,302.839 237.894,305.823C237.064,306.31 236,305.736 236,304.801L236,284.2Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PlayIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-24'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
