<template>
  <svg
    class="fill-current stroke-current"
    :class="classObject"
    viewBox="0 0 430 98"
    @click="$emit('onClick')"
  >
    <g>
        <path d="M36.8,66.5L38.8,66.5C39.2,66.5 39.6,66.6 39.8,66.7C40,66.8 40.1,67.1 40.1,67.6L40.1,73.9L32.8,73.9L32.8,64.9L28.8,64.9L28.8,73.9L22,73.9L22,68.1C22,67.6 22.1,67.3 22.3,67.2C22.5,67.1 22.8,67 23.2,67L24.8,67L24.5,63L18,63L18,80.9L21.3,80.9L21.4,78.7L39.6,78.7C40.3,78.7 40.6,79.1 40.7,79.8L40.8,81.1L44,81.1L44,62.5L37,62.5L36.8,66.5Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M40.5,52.5L34.8,52.5L34.8,49.2C34.8,46.2 34,43.7 32.5,41.9C31,40.1 28.8,39.1 26.1,39.1C23.4,39.1 21.3,39.9 19.9,41.5C18.5,43.1 17.8,45.4 17.8,48.3L17.8,59.5L21.1,59.5L21.2,57.3L39.4,57.3C40.1,57.3 40.4,57.7 40.5,58.4L40.6,59.7L44,59.7L44,48.8L40.7,48.8L40.5,52.5ZM31.1,52.5L21.8,52.5L21.8,50.1C21.8,48 22.1,46.4 22.7,45.4C23.3,44.4 24.6,43.9 26.4,43.9C29.5,43.9 31,45.8 31,49.6L31,52.5L31.1,52.5Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M40.7,21.6L40.6,24.7L21.9,24.7L21.9,22C21.9,21.5 22,21.2 22.2,21.1C22.4,21 22.7,20.9 23.1,20.9L24.9,20.9L24.6,16.9L18,16.9L18,37.3L24.6,37.3L24.9,33.3L23.1,33.3C22.3,33.3 21.9,33 21.9,32.4L21.9,29.4L39.6,29.4C40.3,29.4 40.6,29.8 40.7,30.5L40.8,32.6L44,32.6L44,21.6L40.7,21.6Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M429.5,97.8L429.5,0L0,0L0,97.8L429.5,97.8ZM7.3,7.2L55.4,7.2L55.4,90.6L7.3,90.6L7.3,7.2ZM422.3,90.7L62.7,90.7L62.7,7.2L422.3,7.2L422.3,90.7Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M114.7,73.4L114.7,62.4L95.2,62.4L95.2,22.9L81.5,22.9L81.5,73.4L114.7,73.4Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M148.5,62.3L132.1,62.3L132.1,52.8L147.3,52.8L147.3,41.8L132.1,41.8L132.1,33.9L148.5,33.9L148.5,22.9L118.5,22.9L118.5,73.4L148.5,73.4L148.5,62.3Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M175.6,74.1C182.7,74.1 189.2,73.1 195.1,71.1L195.1,43.9L173.3,43.9L173.3,54.4L182.3,54.4L182.3,62.3C180.6,62.7 178.7,62.9 176.7,62.9C173,62.9 170.2,61.7 168.2,59.2C166.2,56.7 165.2,53.1 165.2,48.4C165.2,43.6 166.3,39.9 168.5,37.3C170.7,34.7 173.8,33.4 177.7,33.4C182.1,33.4 186,34.3 189.7,36.1L194,25.4C191.9,24.5 189.4,23.7 186.6,23.1C183.8,22.5 180.8,22.2 177.6,22.2C169.2,22.2 162.7,24.5 158,29C153.3,33.5 151,39.9 151,48C151,56.4 153.1,62.8 157.4,67.3C161.7,71.8 167.9,74.1 175.6,74.1Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M231.2,62.3L214.9,62.3L214.9,52.8L230,52.8L230,41.8L214.9,41.8L214.9,33.9L231.2,33.9L231.2,22.9L201.2,22.9L201.2,73.4L231.2,73.4L231.2,62.3Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M247.9,50.7C247.9,47.8 247.7,43.5 247.2,37.9L247.5,37.9L266,73.4L283.9,73.4L283.9,22.9L271.7,22.9L271.7,45.7C271.7,48.8 271.9,52.9 272.2,58L272,58L253.6,22.9L235.8,22.9L235.8,73.4L247.9,73.4L247.9,50.7Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M326.5,66.6C331.1,62 333.5,55.5 333.5,47.1C333.5,39.3 331.3,33.3 326.9,29.1C322.5,25 316.2,22.9 308.1,22.9L290.6,22.9L290.6,73.4L306.9,73.4C315.3,73.4 321.9,71.2 326.5,66.6ZM304.2,33.9L308.2,33.9C311.9,33.9 314.7,35 316.5,37.3C318.3,39.5 319.2,43 319.2,47.6C319.2,52.6 318.2,56.2 316.3,58.6C314.4,61 311.3,62.2 307.2,62.2L304.2,62.2L304.2,33.9Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M351.5,74.1C355.5,74.1 359,73.4 362,72.1C365,70.8 367.3,68.9 368.9,66.4C370.5,64 371.3,61.2 371.3,58.1C371.3,54.7 370.5,51.9 368.8,49.6C367.1,47.4 364.1,45.3 359.7,43.3C355.2,41.2 352.4,39.8 351.4,39C350.4,38.2 349.9,37.3 349.9,36.3C349.9,35.4 350.3,34.6 351.1,34C351.9,33.4 353.2,33.1 355,33.1C358.4,33.1 362.5,34.2 367.2,36.3L371.4,25.8C366,23.4 360.6,22.2 355.4,22.2C349.5,22.2 344.8,23.5 341.4,26.1C338,28.7 336.3,32.3 336.3,37C336.3,39.5 336.7,41.6 337.5,43.5C338.3,45.3 339.5,46.9 341.2,48.3C342.8,49.7 345.3,51.2 348.6,52.7C352.2,54.3 354.5,55.4 355.3,55.9C356.1,56.4 356.8,57 357.1,57.5C357.5,58 357.7,58.6 357.7,59.3C357.7,60.4 357.2,61.3 356.3,62C355.4,62.7 353.9,63.1 351.8,63.1C349.5,63.1 346.9,62.7 344.1,62C341.3,61.3 338.6,60.2 336,58.9L336,71.1C338.5,72.3 340.8,73.1 343.1,73.6C345.3,73.9 348.1,74.1 351.5,74.1Z" style="fill:white;fill-rule:nonzero;"/>
        <path d="M374.3,72.6C375.6,73.8 377.4,74.3 379.7,74.3C381.9,74.3 383.6,73.7 384.9,72.6C386.2,71.4 386.8,69.8 386.8,67.7C386.8,65.6 386.2,63.9 385,62.8C383.8,61.6 382,61.1 379.7,61.1C377.3,61.1 375.5,61.7 374.2,62.8C372.9,64 372.3,65.6 372.3,67.7C372.4,69.8 373,71.4 374.3,72.6Z" style="fill:white;fill-rule:nonzero;"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-64'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
