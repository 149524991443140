<template>
  <div class="blog h-full">
    <TopBanner title="Legends Blog" backgroundColor="bg-blog" fixed />

    <div class="pt-64 px-6 w-full bg-white text-black flex flex-wrap">
      <div
        class="w-full md:w-1/3 lg:w-1/4 px-2 md:px-4 py-2 md:py-6"
      >
        <div
          class="flex-col justify-evenly  text-white flex flex-wrap items-center border-2 border-blog rounded-xl w-full h-72 md:h-80"
        >
          <div
            class="bg-white flex-1 w-full rounded-t-xl bg-cover bg-center bg-no-repeat flex justify-end items-end"
          >
            <div class="w-full">
              <CustomInputThree
                v-model="searchQuery"
                type="text"
                name=""
                placeholder="Type your search query here"
              />
            </div>
          </div>

          <div
            @click="searchPosts"
            class="cursor-pointer w-full rounded-b-xl p-4 md:p-6 flex flex-wrap items-betwen bg-blog hover:bg-blog-dark"
          >
            <div class="w-full">
              <div class="w-full bg-cover bg-center bg-no-repeat">
                <p class="text-xs md:text-sm uppercase font-medium tracking-widest truncate">Click here to submit</p>
                <h3 class="h-15 md:h-13 lg:h-14 overflow-hidden text-lg md:text-xl lg:text-2xl font-extrabold leading-none">Search</h3>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div
        v-for="(post, index) in posts" :key="index"
        class="w-1/2 md:w-1/3 lg:w-1/4 px-2 md:px-4 py-2 md:py-6"
      >
        <CardFour
          :title="post.title"
          :subTitle="post.subTitle"
          backgroundColor="bg-blog"
          backgroundHoverColor="bg-blog-dark"
          :imagePath="post.imageSrc"
          @onClick="goToPost(post)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBanner from '@/components/TopBanner.vue'
import CardFour from '@/components/CardFour.vue'
import CustomInputThree from '@/components/elements/CustomInputThree.vue'
import axios from 'axios'

export default {
  name: 'blog',
  components: {
    CardFour,
    TopBanner,
    CustomInputThree
  },
  data () {
    return {
      searchQuery: '',
      posts: []
    }
  },
  computed: {
  },
  methods: {
    searchPosts () {
      this.$store.dispatch('setShowLoading', true)
      var toReturn = []
      const updater = new URLSearchParams()
      updater.append('searchQuery', this.searchQuery)
      axios.post(process.env.VUE_APP_ROOT_API + '/search_posts', updater).then(response => {
        console.log(response.data)
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        for (var i = 0; i < data.length; i++) {
          toReturn.push({
            id: data[i].id,
            title: data[i].title,
            subTitle: data[i].sub_title,
            content: data[i].content
          })
          if (data[i].image) toReturn[i].imageSrc = data[i].image.path
        }
        this.posts = toReturn
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    getBlog () {
      this.$store.dispatch('setShowLoading', true)
      var toReturn = []
      axios.get(process.env.VUE_APP_ROOT_API + '/blog_posts').then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        for (var i = 0; i < data.length; i++) {
          toReturn.push({
            id: data[i].id,
            title: data[i].title,
            subTitle: data[i].sub_title,
            content: data[i].content
          })
          if (data[i].image) toReturn[i].imageSrc = data[i].image.path
        }
        this.posts = toReturn
      }).catch(error => {
        console.log('Error', error)
      })
    },
    goToPost (post) {
      this.$router.push('/blogpost/' + post.id)
    }
  },
  mounted () {
    this.getBlog()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
