<template>
  <div class="gym h-full">
    <TopBannerTwo :title="gymWorkout.title" :subTitle="seriesText" backgroundColor="bg-gym" :imagePath="gymWorkout.imagePath" />
    <NavBar
      :links="navLinks"
      backgroundColor="bg-gym"
      textColor="text-white"
      backgroundActiveColor="bg-white"
      textActiveColor="text-gym"
      @onClick="updateNav"
    />

    <!-- OVERVIEW -->
    <div v-if="navLinks[0].active" class="text-gym">
      <div class="p-8 sm:px-20 lg:px-40 md:py-16">
        <div class="mb-12">
          <div v-html="gymWorkout.description"></div>
        </div>
        <div class="flex flex-wrap border-b border-gym-light">
          <div class="w-1/2 md:w-1/4 mb-8 pr-2 border-l border-gym-light pl-4 py-2">
            <p class="text-xs md:text-sm leading-tight uppercase font-medium tracking-widest">Length</p>
            <h3 class="text-lg sm:text-2xl lg:text-4xl leading-none font-extrabold">{{gymWorkout.time}}</h3>
          </div>
          <div class="w-1/2 md:w-1/4 mb-8 px-2 border-l border-gym-light pl-4 py-2">
            <p class="text-xs md:text-sm leading-tight uppercase font-medium tracking-widest">Effort</p>
            <RatingScale class="mt-2" :rating="gymWorkout.effort" maxRating="5" dotColor="bg-gym" circleColor="border-gym" />
          </div>
          <div class="w-1/2 md:w-1/4 mb-8 px-2 border-l border-gym-light pl-4 py-2">
            <p class="text-xs md:text-sm leading-tight uppercase font-medium tracking-widest">Completed By</p>
            <h3 class="text-lg sm:text-2xl lg:text-4xl leading-none font-extrabold">
              {{completedByNumber}}
              <span v-if="completedByNumber===1">Legend</span>
              <span v-if="completedByNumber!==1">Legends</span>
              </h3>
          </div>
          <div class="w-1/2 md:w-1/4 mb-8 px-2 border-l border-gym-light pl-4 py-2">
            <p class="text-xs md:text-sm leading-tight uppercase font-medium tracking-widest">Times Complete</p>
            <h3 class="text-lg sm:text-2xl lg:text-4xl leading-none font-extrabold">{{userTimesCompleted}}</h3>
          </div>
        </div>
        <div class="mt-12 border-l border-gym-light pl-4 py-2">
          <p class="text-xs md:text-sm leading-tight uppercase font-medium tracking-widest">Up Next in Series</p>
          <div class="flex mt-4">
            <div class="w-40">
              <CustomButton @onClick="goToPreviousItem" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark" :disabled="!previousId">Previous</CustomButton>
            </div>
            <div class="w-40 ml-4">
              <CustomButton @onClick="goToNextItem" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark" :disabled="!nextId">Next</CustomButton>
            </div>
          </div>
        </div>
        <div class="mt-10 pt-10 border-t border-gym-light flex">
          <CustomButton
            @onClick="addToList"
            :bgColor="saveButton.bgColor"
            :textColor="saveButton.textColor"
            borderColor="border-gym"
            hoverTextColor="hover:text-gym-dark"
            hoverBorderColor="hover:border-gym-dark"
            :disabled="saveButton.disabled"
            class="mr-2"
          >
            {{saveButton.text}}
          </CustomButton>
          <CustomButton
            @onClick="complete"
            textColor="text-gym"
            borderColor="border-gym"
            hoverTextColor="hover:text-gym-dark"
            hoverBorderColor="hover:border-gym-dark"
            class="ml-2"
          >
            Complete
          </CustomButton>
        </div>
        <div class="mt-12 border-l border-gym-light pl-4 py-2">
          <p class="text-xs md:text-sm leading-tight uppercase font-medium tracking-widest">Add To DIY Planner</p>
          <div class="flex flex-wrap mt-4">
            <div class="w-18 mt-1">
              <CustomButton @onClick="addToDay(gymWorkout, 'MONDAY')" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark">Mon</CustomButton>
            </div>
            <div class="w-18 mt-1 ml-0 md:ml-2">
              <CustomButton @onClick="addToDay(gymWorkout, 'TUESDAY')" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark">Tue</CustomButton>
            </div>
            <div class="w-18 mt-1 ml-0 md:ml-2">
              <CustomButton @onClick="addToDay(gymWorkout, 'WEDNESDAY')" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark">Wed</CustomButton>
            </div>
            <div class="w-18 mt-1 ml-0 md:ml-2">
              <CustomButton @onClick="addToDay(gymWorkout, 'THURSDAY')" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark">Thu</CustomButton>
            </div>
            <div class="w-18 mt-1 ml-0 md:ml-2">
              <CustomButton @onClick="addToDay(gymWorkout, 'FRIDAY')" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark">Fri</CustomButton>
            </div>
            <div class="w-18 mt-1 ml-0 md:ml-2">
              <CustomButton @onClick="addToDay(gymWorkout, 'SATURDAY')" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark">Sat</CustomButton>
            </div>
            <div class="w-18 mt-1 ml-0 md:ml-2">
              <CustomButton @onClick="addToDay(gymWorkout, 'SUNDAY')" textColor="text-gym" borderColor="border-gym" hoverTextColor="hover:text-gym-dark" hoverBorderColor="hover:border-gym-dark">Sun</CustomButton>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- VIDEOS -->
    <div v-if="navLinks[1].active" class="text-gym">
      <div class="p-8 md:px-40 md:py-24">
        <div
          v-for="(video, index) in gymWorkout.videos"
          :key="index"
          class="mb-12 md:mb-24"
        >
          <p class="text-xs md:text-sm leading-tight uppercase font-medium tracking-widest mb-4">{{video.title}}</p>
          <iframe class="w-full" :height="videoHeight" :src="video.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="mt-10 pt-10 border-t border-gym-light">
          <CustomButton
            @onClick="complete"
            textColor="text-gym"
            borderColor="border-gym"
            hoverTextColor="hover:text-gym-dark"
            hoverBorderColor="hover:border-gym-dark"
          >
            Complete
          </CustomButton>
        </div>
      </div>
    </div>

    <!-- STEPS -->
    <div v-if="navLinks[2].active" class="text-gym">
      <div class="p-8 md:px-40 md:py-24">
        <div
          v-for="(step, index) in gymWorkout.steps"
          :key="index"
          class="mb-12 md:mb-12"
        >
          <div class="w-full flex flex-wrap justify-between items-center">
            <div>
              <p class="text-xs md:text-sm leading-tight uppercase font-medium tracking-widest">Step {{index + 1}}</p>
              <h3 class="text-lg sm:text-2xl lg:text-4xl leading-none font-extrabold">{{step.title}}</h3>
            </div>
            <div class="border-l border-gym-light pl-4 py-4">
              <h3 class="text-base sm:text-xl lg:text-3xl leading-none font-medium">{{step.time}}</h3>
            </div>
          </div>
          <div class="w-full mt-4 border-t border-gym-light">
            <p class="text-sm md:text-base leading-normal font-normal mt-6">{{step.description}}</p>
          </div>
        </div>
        <div class="mt-10 pt-10 border-t border-gym-light">
          <CustomButton
            @onClick="complete"
            textColor="text-gym"
            borderColor="border-gym"
            hoverTextColor="hover:text-gym-dark"
            hoverBorderColor="hover:border-gym-dark"
          >
            Complete
          </CustomButton>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import TopBannerTwo from '@/components/TopBannerTwo.vue'
import NavBar from '@/components/NavBar.vue'
import CustomButton from '@/components/elements/CustomButton.vue'
import RatingScale from '@/components/elements/RatingScale.vue'
import axios from 'axios'

export default {
  name: 'gymworkout',
  components: {
    TopBannerTwo,
    NavBar,
    CustomButton,
    RatingScale
  },
  data () {
    return {
      saveButton: {
        text: 'Save',
        textColor: 'text-gym',
        bgColor: '',
        disabled: false
      },
      completedByNumber: 0,
      userTimesCompleted: 0,
      gymWorkoutCategory: {},
      gymWorkout: {},
      currentWorkoutLocation: '',
      navLinks: [
        {
          title: 'Overview',
          active: true
        },
        {
          title: 'Video',
          active: false
        },
        {
          title: 'Step By Step',
          active: false
        }
      ]
    }
  },
  computed: {
    previousId () {
      var toReturn = ''
      var listToLoop = this.gymWorkoutCategory.gymWorkouts
      if (listToLoop) {
        for (var i = 0; i < listToLoop.length; i++) {
          // find the current workout
          if (parseInt(listToLoop[i].id) === parseInt(this.$route.params.workoutid)) {
            // make sure it's not the last in the list
            if (i !== 0) toReturn = listToLoop[i - 1].id
          }
        }
      }
      return toReturn
    },
    nextId () {
      var toReturn = ''
      var listToLoop = this.gymWorkoutCategory.gymWorkouts
      if (listToLoop) {
        for (var i = 0; i < listToLoop.length; i++) {
          // find the current workout
          if (parseInt(listToLoop[i].id) === parseInt(this.$route.params.workoutid)) {
            // make sure it's not the last in the list
            if (i < listToLoop.length - 1) toReturn = listToLoop[i + 1].id
          }
        }
      }
      return toReturn
    },
    seriesText () {
      return 'Series | ' + this.currentWorkoutLocation + ' of ' + this.totalNumberOfWorkouts
    },
    videoHeight () {
      var toReturn = '300'
      if (this.$mq === 'sm') toReturn = '300'
      else if (this.$mq === 'md') toReturn = '300'
      else if (this.$mq === 'lg') toReturn = '400'
      else if (this.$mq === 'xl') toReturn = '600'
      else if (this.$mq === 'xxl') toReturn = '600'
      else if (this.$mq === 'xxxl') toReturn = '600'
      return toReturn
    }
  },
  methods: {
    addToList () {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('gym_workout_id', this.gymWorkout.id)
      updater.append('area', 'GYM')
      updater.append('category_id', this.$route.params.groupid)
      axios.post(process.env.VUE_APP_ROOT_API + '/save-to-list', updater, authorization).then(response => {
        this.saveButton = {
          text: 'Saved',
          textColor: 'text-white',
          bgColor: 'bg-gym',
          disabled: true
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    complete () {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('gym_workout_id', this.gymWorkout.id)
      updater.append('category_id', this.$route.params.groupid)
      axios.post(process.env.VUE_APP_ROOT_API + '/complete-gym-workout', updater, authorization).then(response => {
        this.$router.push('/profile/0')
      }).catch(error => {
        // this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    addToDay (workout, day) {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('day', day)
      updater.append('workout_id', workout.id)
      updater.append('area', 'GYM')
      axios.post(process.env.VUE_APP_ROOT_API + '/add-to-day', updater, authorization).then(response => {
        this.$router.push('/profile/0')
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    },
    updateNav (index) {
      for (var i = 0; i < this.navLinks.length; i++) {
        if (i === index) this.navLinks[i].active = true
        else this.navLinks[i].active = false
      }
    },
    goToPreviousItem () {
      this.$router.push('/gymworkout/' + this.$route.params.groupid + '/' + this.previousId)
      this.getGymWorkout()
    },
    goToNextItem () {
      this.$router.push('/gymworkout/' + this.$route.params.groupid + '/' + this.nextId)
      this.getGymWorkout()
    },
    getGymWorkout () {
      this.$store.dispatch('setShowLoading', true)
      this.saveButton = {
        text: 'Save',
        textColor: 'text-gym',
        bgColor: '',
        disabled: false
      }
      var toReturn = {}
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('id', this.$route.params.groupid)
      axios.post(process.env.VUE_APP_ROOT_API + '/gym-workout-category', updater, authorization).then(response => {
        var data = response.data
        toReturn = {
          id: data.id,
          title: data.title,
          gymWorkouts: []
        }

        for (var i = 0; i < data.workouts.length; i++) {
          toReturn.gymWorkouts.push({
            id: data.workouts[i].id,
            title: data.workouts[i].title,
            effort: data.workouts[i].effort,
            time: data.workouts[i].time,
            videos: data.workouts[i].videos,
            steps: data.workouts[i].steps,
            description: data.workouts[i].description
          })
          if (data.workouts[i].image) toReturn.gymWorkouts[i].imagePath = data.workouts[i].image.path
        }
        this.gymWorkoutCategory = toReturn

        // find the workout within the group
        var listToLoop = this.gymWorkoutCategory.gymWorkouts
        for (var j = 0; j < listToLoop.length; j++) {
          if (parseInt(listToLoop[j].id) === parseInt(this.$route.params.workoutid)) {
            this.gymWorkout = listToLoop[j]
            this.totalNumberOfWorkouts = this.gymWorkoutCategory.gymWorkouts.length
            this.currentWorkoutLocation = j + 1
          }
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error', error)
      }).finally(() => {
        this.getUserTimesCompleted()
      })
    },
    getUserTimesCompleted () {
      this.userTimesCompleted = 0
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      updater.append('gym_workout_id', this.gymWorkout.id)
      axios.post(process.env.VUE_APP_ROOT_API + '/gym-workout-completed-single', updater, authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)
        this.completedByNumber = response.data.completedByNumber

        for (var i = 0; i < response.data.completed.length; i++) {
          this.userTimesCompleted = this.userTimesCompleted + parseInt(response.data.completed[i].quantity)
        }
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    }
  },
  mounted () {
    this.getGymWorkout()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
