<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 50 59.3"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M25,53c-6.7,0-12.9-2.4-17.8-6.3v12.6l17.5-3.9l17.5,3.9V47.1C37.5,50.8,31.5,53,25,53z"/>
    <path class="st0" d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25c13.8,0,25-11.2,25-25S38.8,0,25,0z M34,37.1H15.6v-4.2l8.9-8.3
      c0.9-0.8,1.4-1.5,1.7-2.1c0.3-0.6,0.4-1.2,0.4-1.7c0-0.8-0.3-1.4-0.8-1.8c-0.5-0.4-1.3-0.6-2.3-0.6c-0.9,0-1.7,0.2-2.4,0.6
      c-0.7,0.4-1.3,1-1.8,1.7l-5-2.8c0.9-1.5,2.2-2.7,3.9-3.6c1.7-0.9,3.6-1.3,5.9-1.3c1.8,0,3.4,0.3,4.8,0.9c1.4,0.6,2.5,1.4,3.2,2.5
      c0.8,1.1,1.2,2.4,1.2,3.8c0,1.3-0.3,2.5-0.8,3.6C32,25,31,26.2,29.4,27.7l-4.5,4.1H34V37.1z"/>
  </svg>
</template>

<script>
export default {
  name: 'BadgeIcon200',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-64'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
