<template>
  <div
    class="
      h-56
      items-start
      pt-12
      md:h-40
      md:items-center
      md:pt-0
      w-full flex justify-center bg-black px-4
    "
  >
    <div class="flex">
      <div class="w-24 md:w-64 flex items-center justify-end">
        <NavFooterButton @onClick="goToTerms">
          Terms &amp; Conditions
        </NavFooterButton>
      </div>
      <div class="flex items-center mx-2 md:mx-8">
        <LogoRound  heightClass="h-18 md:h-24" />
      </div>
      <div class="w-24 md:w-64 flex items-center justify-start">
        <NavFooterButton @onClick="goToContact">
          Get In Contact
        </NavFooterButton>
      </div>
    </div>
  </div>
</template>

<script>
import NavFooterButton from '@/components/elements/NavFooterButton.vue'
import LogoRound from '@/components/icons/LogoRound.vue'

export default {
  name: 'Footer',
  components: {
    NavFooterButton,
    LogoRound
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    goToContact () {
      this.$router.push('/contact')
    },
    goToTerms () {
      this.$router.push('/terms')
    }
  }
}

</script>

<style lang="scss">

</style>
