var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex items-end",class:[
    _vm.backgroundColor,
    {
      'fixed':_vm.fixed
    }
  ]},[_c('div',{staticClass:"w-full flex",class:{
      'justify-around':!_vm.leftAligned,
      'justify-around md:justify-start pl-0 md:pl-20':_vm.leftAligned,
    }},_vm._l((_vm.links),function(link,index){return _c('div',{key:index,staticClass:"w-64 h-12 rounded-t-xl flex items-center justify-center transition-all duration-200",class:_vm.getClass(link)},[_c('p',{staticClass:"cursor-pointer text-xs md:text-sm leading-none uppercase font-medium tracking-widest",on:{"click":function($event){return _vm.emitIndex(index)}}},[_vm._v(_vm._s(link.title))])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }