<template>
  <div class="signup h-full">
    <div class="w-full bg-primary flex px-8 md:px-24 pb-8 md:pb-24 pt-24">
      <div class="w-full">
        <Logo heightClass="h-8 mb-2" />
          <h3 class="font-extrabold leading-none">membership plan.</h3>
      </div>
    </div>
    <div class="px-12 md:px-24 pt-12 md:pt-12 text-primary">
      <div v-if="!messageSent&&!messageFailed" class="flex flex-wrap w-full">
        <div class="w-full md:w-1/4 mt-12">
          <SignUpForm @onSubmit="signUpUser" />
        </div>

        <div class="w-full md:w-1/2 lg:w-1/2 xxl:w-1/4 mt-12">
          <div class="md:ml-12 border-t border-primary">
            <p class="uppercase text-sm tracking-widest mt-4">Breakdown</p>
            <div class="flex justify-between mt-4">
              <h3 class="font-extrabold leading-none text-xl">{{product.name}}</h3>
              <p class="uppercase text-sm tracking-widest">{{priceFreq}}</p>
            </div>
            <div class="flex justify-between mt-4">
              <h3 class="font-extrabold leading-none text-xl">Trial period</h3>
              <p class="uppercase text-sm tracking-widest">{{trialPeriod}}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="messageSent" class="w-full">
        <h3 class="text-xl md:text-3xl text-center text-primary">Last step...</h3>
        <div class="w-full mt-4">
          <CustomButton
            @onClick="submitStripe"
            textColor="text-primary"
            borderColor="border-primary"
            hoverTextColor="hover:text-kitchen-dark"
            hoverBorderColor="hover:border-kitchen-dark"
          >
            Enter Payment Details
          </CustomButton>
        </div>

        <div id="error-message"></div>
      </div>
      <div v-if="messageFailed" class="w-full">
        <h3 class="text-xl md:text-3xl text-center text-red-500">Registration Failed!</h3>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import shared from '@/shared'
import axios from 'axios'
import Logo from '@/components/icons/Logo.vue'
import SignUpForm from '@/components/SignUpForm.vue'
import CustomButton from '@/components/elements/CustomButton.vue'

export default {
  name: 'signup',
  components: {
    Logo,
    SignUpForm,
    CustomButton
  },
  data () {
    return {
      product: {},
      messageSent: false,
      messageFailed: false,
      newUuid: '',
      stripeCheckout: {}
    }
  },
  computed: {
    trialPeriod () {
      if (this.product.plan) {
        if (this.product.plan.trial_period_days) return this.product.plan.trial_period_days + ' Days'
        else return 'none'
      } else {
        return 'none'
      }
    },
    priceFreq () {
      if (this.product.plan) {
        return shared.formatPrice(parseFloat(this.product.plan.actual_amount), '$') + '/' + this.product.plan.interval
      } else {
        return ''
      }
    }
  },
  methods: {
    signUpUser (obj) {
      const updater = new URLSearchParams()
      updater.append('name', obj.name)
      updater.append('surname', obj.surname)
      updater.append('phone', obj.phone)
      updater.append('product', this.product.id)
      updater.append('email', obj.email)
      updater.append('username', obj.email)
      updater.append('password', obj.password)
      updater.append('password_confirmation', obj.password_confirmation)
      updater.append('price', this.product.plan.actual_amount)
      updater.append('trial_period_days', this.product.plan.trial_period_days)
      updater.append('promotion_code', obj.promotion_code)
      axios.post(process.env.VUE_APP_ROOT_API + '/create_checkout', updater).then(response => {
        this.stripeCheckout = response.data.stripe
        if (this.stripeCheckout.id && response.data.user.uuid) {
          this.$router.push('/signupfinish/success/' + response.data.user.uuid)

          // this.newUuid = response.data.user.uuid
          // this.messageSent = true
          // this.messageFailed = false
        } else {
          this.messageSent = false
          this.messageFailed = true
        }
      }).catch(error => {
        this.messageSent = false
        this.messageFailed = true
        console.log('Error 1', error)
      })
    },
    getProduct () {
      axios.get(process.env.VUE_APP_ROOT_API + '/stripe_plan').then(response => {
        this.product = response.data
      }).catch(error => {
        console.log('Error 1', error)
      })
    },
    submitStripe () {
      // var stripe = window.Stripe('pk_test_9NXlV5wps49upg1QL5THVLjq') // FAKE
      // // var stripe = window.Stripe('pk_live_dcnrdZcLun4T6LSrHwLFLaxV00fVz5SsqQ') // REAL
      // stripe.redirectToCheckout({
      //   sessionId: this.stripeCheckout.id
      // }).then(function (result) {
      //   console.log('ERROR', result)
      // })
    }
  },
  mounted () {
    this.getProduct()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
