<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 50 50"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M25,46.4C13.2,46.4,3.6,36.8,3.6,25
      S13.2,3.6,25,3.6S46.4,13.2,46.4,25S36.8,46.4,25,46.4z"/>
    <path class="st0" d="M20.6,33l-8.4-8.4c-0.7-0.7-1.8-0.7-2.5,0l0,0c-0.7,0.7-0.7,1.8,0,2.5l9.3,9.3c1.6,1.6,4.2,1.6,5.9,0l16.9-16.9
      c0.7-0.7,0.7-1.8,0-2.5l0,0c-0.7-0.7-1.8-0.7-2.5,0l-16,16C22.5,33.7,21.3,33.7,20.6,33z"/>
  </svg>
</template>

<script>
export default {
  name: 'TickIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-8'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
