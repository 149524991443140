<template>
  <div class="blogpost h-full">
    <TopBannerThree :title="post.title" :subTitle="post.subTitle" backgroundColor="bg-blog" :imagePath="post.imageSrc" />
    <div v-html="post.content" class="p-8 sm:px-20 lg:px-40 md:py-16 text-black">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBannerThree from '@/components/TopBannerThree.vue'
import axios from 'axios'

export default {
  name: 'blogpost',
  components: {
    TopBannerThree
  },
  data () {
    return {
      post: {}
    }
  },
  computed: {
  },
  methods: {
    getBlogPost () {
      this.$store.dispatch('setShowLoading', true)

      this.post = {}
      const updater = new URLSearchParams()
      updater.append('id', this.$route.params.postid)
      axios.post(process.env.VUE_APP_ROOT_API + '/blog_post', updater).then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        this.post.id = data.id
        this.post.title = data.title
        this.post.subTitle = data.sub_title
        this.post.content = data.content
        if (data.image) this.post.imageSrc = data.image.path
        var metaTag = document.querySelector('title')
        metaTag.innerHTML = 'Essential Personal Training - ' + this.post.title
        metaTag = document.querySelector('[name="description"]')
        metaTag.setAttribute('content', this.post.content.replace(/(<([^>]+)>)/gi, ''))
        this.$forceUpdate()
      }).catch(error => {
        console.log('Error', error)
      })
    }
  },
  mounted () {
  },
  created () {
    this.getBlogPost()
  }
}
</script>

<style lang="scss">

</style>
