<template>
  <div
    class="flex flex-wrap flex-col justify-evenly w-full h-72 md:h-80 text-white"
  >
    <div
      :style="{ backgroundImage: 'url(' + imagePath + ')' }"
      class="flex-1 w-full rounded-t-xl bg-cover bg-center bg-no-repeat flex justify-end p-4"
      :class="[
        customCss
      ]"
    >
      <div v-if="showCross" class="z-10 w-10 h-10 rounded-full bg-white flex items-center justify-center">
        <CrossIcon @onClick="$emit('onCrossClick')" heightClass="h-8" isLink />
      </div>
    </div>

    <div
      @click="$emit('onClick')"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      class="cursor-pointer w-full rounded-b-xl p-4 md:p-6 flex flex-wrap items-betwen"
      :class="[
        customCss
      ]"
    >
      <div class="w-full">
        <div class="w-full bg-cover bg-center bg-no-repeat">
          <p class="text-xs md:text-sm uppercase font-medium tracking-widest truncate">{{subTitle}}</p>
          <h3 class="h-15 md:h-13 lg:h-14 overflow-hidden text-lg md:text-xl lg:text-2xl font-extrabold leading-none">{{title}}</h3>
        </div>
      </div>

      <div class="w-full flex items-end">
        <div class="w-full flex mt-4">
          <div class="w-1/3 md:w-1/4 flex items-center h-12">
            <div class="-mt-4 flex flex-col justify-between h-full py-2">
              <p class="text-xs md:text-sm leading-snug uppercase font-medium tracking-widest">{{firstTitle}}</p>
              <h3 class="text-base md:text-2xl leading-snug font-extrabold">{{firstValue}}</h3>
            </div>
          </div>
          <div class="w-2/3 md:w-1/4 flex items-center h-12 border-l border-white">
            <div class="-mt-4 pl-4 md:pl-4 pr-4 md:pr-4 flex flex-col justify-between h-full py-2">
              <p class="text-xs md:text-sm leading-snug uppercase font-medium tracking-widest">{{secondTitle}}</p>
              <div v-if="secondValueIsRating" class="mt-2">
                <RatingScale :rating="secondValue" dotColor="bg-white" circleColor="border-white" />
              </div>
              <h3 v-if="!secondValueIsRating" class="text-base md:text-2xl leading-snug font-extrabold">{{secondValue}}</h3>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import RatingScale from '@/components/elements/RatingScale.vue'
import CrossIcon from '@/components/icons/CrossIcon.vue'

export default {
  name: 'CardThree',
  components: {
    RatingScale,
    CrossIcon
  },
  props: {
    title: String,
    subTitle: String,
    firstTitle: String,
    firstValue: String,
    secondTitle: String,
    secondValue: [String, Number],
    backgroundColor: {
      type: String,
      default: 'bg-gray-400'
    },
    backgroundHoverColor: {
      type: String,
      default: 'bg-gray-900'
    },
    imagePath: String,
    secondValueIsRating: {
      type: Boolean,
      default: true
    },
    showCross: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hovered: false
    }
  },
  computed: {
    // cutTitle () {
    //   var length = 22
    //   if (this.$mq === 'sm') length = 22
    //   else if (this.$mq === 'md') length = 20
    //   else if (this.$mq === 'lg') length = 25
    //   else if (this.$mq === 'xl') length = 20
    //   else if (this.$mq === 'xxl') length = 20
    //   else if (this.$mq === 'xxxl') length = 20

    //   return this.title.substring(0, length) + '...'
    // },
    customCss () {
      var toReturn = ''

      if (!this.hovered) {
        toReturn += this.backgroundColor
      } else {
        toReturn += this.backgroundHoverColor
      }
      return toReturn
    }
  },
  methods: {
  }
}

</script>

<style lang="scss">

</style>
