<template>
  <div
    class="w-full"
    :class="[
      {
        'fixed':fixed
      }
    ]"
    >
    <div
      :style="{ backgroundImage: 'url(' + imagePath + ')' }"
      class="flex items-end w-full h-64 md:h-128 bg-cover bg-center bg-no-repeat"
    >
    </div>
    <div class="w-full pl-12">
      <h6 @click="goBackAction()" class="text-sm uppercase cursor-pointer">Back</h6>
    </div>
    <div
      class="h-40 rounded-t-xl -mt-12 w-full flex justify-center items-center"
      :class="[
        backgroundColor
      ]"
    >
      <div class="text-center mt-4">
        <h1 class="h-18 md:h-20 overflow-hidden text-3xl md:text-4xl font-extrabold mb-3 leading-tighter md:leading-none">{{title}}</h1>
        <p class="text-xs md:text-sm leading-none uppercase font-medium tracking-widest">{{subTitle}}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopBannerThree',
  components: {
  },
  props: {
    title: String,
    subTitle: String,
    imagePath: String,
    fixed: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: 'bg-gray-500'
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    goBackAction () {
      this.$router.push('/blog')
    }
  }
}

</script>

<style lang="scss">

</style>
