<template>
  <div
    :style="{ backgroundImage: 'url(' + imagePath + ')' }"
    class="flex items-end w-full h-64 bg-cover bg-center bg-no-repeat w-full"
    :class="[
      backgroundColor,
      {
        'fixed':fixed
      }
    ]"
  >
    <div class="w-full text-center">
      <h1 class="text-4xl md:text-6xl font-extrabold mb-3">{{title}}</h1>
      <div class="h-12 bg-white rounded-t-xl">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopBanner',
  components: {
  },
  props: {
    title: String,
    imagePath: String,
    fixed: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: 'bg-gray-500'
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss">

</style>
