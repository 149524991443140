<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 43 43"
    @click="$emit('onClick')"
  >
    <g>
      <path d="M33.2,34.7C32.8,34.7 32.4,34.6 32.1,34.3L8.6,10.7C8,10.1 8,9.2 8.6,8.6C9.2,8 10.1,8 10.7,8.6L34.3,32.2C34.9,32.8 34.9,33.7 34.3,34.3C34,34.6 33.6,34.7 33.2,34.7Z" style="fill:rgb(78,116,128);fill-rule:nonzero;"/>
      <path d="M9.7,34.7C9.3,34.7 8.9,34.6 8.6,34.3C8,33.7 8,32.8 8.6,32.2L32.2,8.6C32.8,8 33.7,8 34.3,8.6C34.9,9.2 34.9,10.1 34.3,10.7L10.7,34.3C10.4,34.6 10.1,34.7 9.7,34.7Z" style="fill:rgb(78,116,128);fill-rule:nonzero;"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CrossIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-8'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
