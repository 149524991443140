<template>
  <svg
    class="LoadingIconTwo fill-current"
    :class="classObject"
    viewBox="0 0 42.9 42.9"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M21.5,40C11.3,40,3,31.7,3,21.5C3,11.3,11.3,3,21.5,3S40,11.3,40,21.4h2.9v0.1H43c0,0,0-0.1,0-0.1 C43,9.6,33.4,0,21.5,0S0,9.6,0,21.5C0,33.4,9.6,43,21.5,43V40z"/>
  </svg>
</template>

<script>
export default {
  name: 'LoadingIconTwo',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-6'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
  .LoadingIconTwo {
    animation: pulse 3s infinite;
    animation-delay: 0s;
  }

  @keyframes pulse {
    0% {
      transform:rotate(0deg);
    }
    30% {
    }
    50% {
      transform:rotate(360deg);
    }
    70% {
    }
    100% {
      transform:rotate(0deg);
    }
  }

</style>
