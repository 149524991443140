<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 50 59.3"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M25,53c-6.7,0-12.9-2.4-17.8-6.3v12.6l17.5-3.9l17.5,3.9V47.1C37.5,50.8,31.5,53,25,53z"/>
    <path class="st0" d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25c13.8,0,25-11.2,25-25S38.8,0,25,0z M32.8,33.6
      c-0.8,1.2-1.9,2.2-3.4,2.9c-1.5,0.7-3.5,1.1-5.7,1.1c-1.7,0-3.3-0.2-4.9-0.6s-3-1-4.2-1.7l2.4-5c0.9,0.6,2,1.1,3.1,1.5
      c1.2,0.3,2.3,0.5,3.4,0.5c1.1,0,2.1-0.2,2.7-0.6s1-1,1-1.8c0-1.5-1.2-2.3-3.6-2.3h-2.8v-4.2l4.2-4.6h-9.3v-5.2h17.1v4.2l-4.9,5.3
      c2,0.4,3.5,1.2,4.5,2.4c1,1.2,1.6,2.7,1.6,4.4C33.9,31.1,33.5,32.4,32.8,33.6z"/>
  </svg>
</template>

<script>
export default {
  name: 'BadgeIcon300',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-64'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
