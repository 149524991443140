<template>
  <div class="flex items-center">
    <div
      @click="updateValue"
      class="w-5 h-5 border border-primary hover:bg-gray-200 rounded-md cursor-pointer"
      :class="{
        '':!actualValue,
        'bg-primary':actualValue
      }"
    >
    </div>
    <h6 class="ml-2 text-lg">Agree to T & C</h6>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      actualValue: false
    }
  },
  methods: {
    updateValue (value) {
      this.actualValue = !this.actualValue
      this.$emit('change', this.actualValue)
    }
  },
  mounted () {
    this.actualValue = this.value
  }
}
</script>
