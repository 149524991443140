<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 283.5 283.5"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M0,271.1C0,184.9,0,98.6,0,12.4c0.2-0.3,0.4-0.6,0.5-1C2.7,4.3,8.5,0,15.9,0c84,0,168,0,251.9,0
      c3.2,0,6.1,0.9,8.8,2.6c3.6,2.3,5.4,5.9,6.9,9.7c0,86.3,0,172.5,0,258.8c-0.2,0.3-0.4,0.5-0.5,0.8c-2.2,7.4-7.9,11.6-15.7,11.6
      c-22.9,0-45.8,0-68.6,0c-1,0-1.9,0-3,0c-0.1-0.5-0.1-0.8-0.1-1.2c0-35.6,0-71.3,0-106.9c0-2,0.9-2.1,2.4-2c10.6,0,21.2,0,31.8,0
      c0.9,0,1.8,0,2.6,0c1.8-14.3,3.6-28.3,5.5-42.5c-14.3,0-28.2,0-41.8,0c0-11.1-0.3-21.9,0.1-32.7c0.4-10.1,7.1-14.6,16-15.1
      c8.1-0.4,16.3-0.2,24.4-0.3c0.9,0,1.9-0.1,2.8-0.2c0-12.9,0-25.4,0-38.2c-6.5-0.4-12.8-0.7-19.1-1c-9.5-0.6-19-0.9-28.4,1.2
      c-11.9,2.6-22,8.1-29.4,18.1c-7.2,9.6-10.3,20.6-10.7,32.3c-0.4,11.1-0.1,22.2-0.1,33.3c0,0.9,0,1.7,0,2.9c-12.6,0-24.8,0-37,0
      c0,14.2,0,28.2,0,42.4c1,0,1.9,0,2.8,0c10.4,0,20.7,0.1,31.1-0.1c2.4,0,3.2,0.5,3.2,3.1c-0.1,34.7-0.1,69.5-0.1,104.2
      c0,0.9,0,1.8,0,2.9c-1.1,0-1.9,0-2.7,0c-44.5,0-89,0-133.6-0.1c-2.1,0-4.3-0.7-6.4-1.4C4.1,280,1.8,275.8,0,271.1z"/>
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-8'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
