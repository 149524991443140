<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 50 50"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25c13.8,0,25-11.2,25-25S38.8,0,25,0z M42.6,21.1l-7.6,6.9
      c-0.4,0.3-0.5,0.8-0.4,1.3l2.1,10.1c0.2,1.1-1,2-2,1.4l-8.9-5.1c-0.4-0.2-0.9-0.2-1.3,0l-8.9,5.1c-1,0.6-2.2-0.3-2-1.4l2.1-10.1
      c0.1-0.5-0.1-1-0.4-1.3l-7.6-6.9c-0.9-0.8-0.4-2.2,0.8-2.3l10.3-1.1c0.5-0.1,0.9-0.4,1.1-0.8l4.2-9.4c0.5-1.1,2-1.1,2.5,0l4.2,9.4
      c0.2,0.4,0.6,0.7,1.1,0.8l10.3,1.1C43,18.9,43.4,20.4,42.6,21.1z"/>
  </svg>
</template>

<script>
export default {
  name: 'GoalIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-64'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
