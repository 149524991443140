<template>
  <div class="w-full">
    <div class="w-full">
      <CustomInputThree
        v-model="submitDetails.email"
        type="text"
        name=""
        placeholder="Email"
        :error="submitErrors.email"
        @input="updateFormErrors()"
      />
    </div>
    <div class="w-full mt-4">
      <CustomInputThree
        v-model="submitDetails.password"
        type="password"
        name=""
        placeholder="Password"
        :error="submitErrors.password"
        @input="updateFormErrors()"
      />
    </div>
    <div class="w-full mt-4">
      <CustomButton
        @onClick="submitForm"
        textColor="text-primary"
        borderColor="border-primary"
        hoverTextColor="hover:text-kitchen-dark"
        hoverBorderColor="hover:border-kitchen-dark"
        class="mr-2"
      >
        Login
      </CustomButton>
    </div>
  </div>
</template>

<script>

import CustomInputThree from '@/components/elements/CustomInputThree.vue'
import CustomButton from '@/components/elements/CustomButton.vue'

export default {
  name: 'LoginForm',
  components: {
    CustomInputThree,
    CustomButton
  },
  props: {
  },
  data () {
    return {
      formHasErrors: false,
      submittedOnce: false,
      submitDetails: {
        email: '',
        password: ''
      },
      submitErrors: {
        email: false,
        password: false
      }
    }
  },
  computed: {
    nameInError () {
      var toReturn = false
      if (!this.submittedOnce) toReturn = false
      else if (this.submitDetails.name) toReturn = false
      else toReturn = true
      return toReturn
    }
  },
  methods: {
    updateFormErrors () {
      this.formHasErrors = false
      if (this.submitDetails.email) this.submitErrors.email = false
      else {
        this.submitErrors.email = true
        this.formHasErrors = true
      }

      if (this.submitDetails.password) this.submitErrors.password = false
      else {
        this.submitErrors.password = true
        this.formHasErrors = true
      }
    },
    submitForm () {
      this.submittedOnce = true
      this.updateFormErrors()
      if (!this.formHasErrors) this.$emit('onSubmit', this.submitDetails)
    }
  }
}

</script>

<style lang="scss">

</style>
