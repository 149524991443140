<template>
  <div
    class="flex flex-wrap flex-col justify-evenly w-full h-72 md:h-80 text-white"
  >
    <div
      :style="{ backgroundImage: 'url(' + imagePath + ')' }"
      class="flex-1 w-full rounded-t-xl bg-cover bg-center bg-no-repeat flex justify-end p-4"
      :class="[
        customCss
      ]"
    >
      <div v-if="showCross" class="z-10 w-10 h-10 rounded-full bg-white flex items-center justify-center">
        <CrossIcon @onClick="$emit('onCrossClick')" heightClass="h-8" isLink />
      </div>
    </div>

    <div
      @click="$emit('onClick')"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      class="cursor-pointer w-full rounded-b-xl p-4 md:p-6 flex flex-wrap items-betwen"
      :class="[
        customCss
      ]"
    >
      <div class="w-full">
        <div class="w-full bg-cover bg-center bg-no-repeat">
          <p class="text-xs md:text-sm uppercase font-medium tracking-widest truncate">{{subTitle}}</p>
          <h3 class="h-15 md:h-13 lg:h-14 overflow-hidden text-lg md:text-xl lg:text-2xl font-extrabold leading-none">{{title}}</h3>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CrossIcon from '@/components/icons/CrossIcon.vue'

export default {
  name: 'CardFour',
  components: {
    CrossIcon
  },
  props: {
    title: String,
    subTitle: String,
    backgroundColor: {
      type: String,
      default: 'bg-gray-400'
    },
    backgroundHoverColor: {
      type: String,
      default: 'bg-gray-900'
    },
    imagePath: String,
    secondValueIsRating: {
      type: Boolean,
      default: true
    },
    showCross: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hovered: false
    }
  },
  computed: {
    // cutTitle () {
    //   var length = 22
    //   if (this.$mq === 'sm') length = 22
    //   else if (this.$mq === 'md') length = 20
    //   else if (this.$mq === 'lg') length = 25
    //   else if (this.$mq === 'xl') length = 20
    //   else if (this.$mq === 'xxl') length = 20
    //   else if (this.$mq === 'xxxl') length = 20

    //   return this.title.substring(0, length) + '...'
    // },
    customCss () {
      var toReturn = ''

      if (!this.hovered) {
        toReturn += this.backgroundColor
      } else {
        toReturn += this.backgroundHoverColor
      }
      return toReturn
    }
  },
  methods: {
  }
}

</script>

<style lang="scss">

</style>
