<template>
  <div class="w-full">
    <div class="w-full">
      <CustomInputThree
        v-model="submitDetails.name"
        type="text"
        name=""
        placeholder="First Name"
        :error="submitErrors.name"
        @input="updateFormErrors()"
      />
    </div>
    <div class="w-full mt-4">
      <CustomInputThree
        v-model="submitDetails.surname"
        type="text"
        name=""
        placeholder="Last Name"
        :error="submitErrors.surname"
        @input="updateFormErrors()"
      />
    </div>
    <div class="w-full mt-4">
      <CustomInputThree
        v-model="submitDetails.phone"
        type="text"
        name=""
        placeholder="Phone"
        :error="submitErrors.phone"
        @input="updateFormErrors()"
      />
    </div>
    <div class="w-full mt-4">
      <CustomInputThree
        v-model="submitDetails.email"
        type="text"
        name=""
        placeholder="Email"
        :error="submitErrors.email"
        @input="updateFormErrors()"
      />
    </div>
    <div class="w-full mt-4">
      <CustomInputThree
        v-model="submitDetails.password"
        type="password"
        name=""
        placeholder="Password"
        :error="submitErrors.password"
        @input="updateFormErrors()"
      />
    </div>
    <div class="w-full mt-4">
      <CustomInputThree
        v-model="submitDetails.password_confirmation"
        type="password"
        name=""
        placeholder="Password Confirmation"
        :error="submitErrors.password_confirmation"
        @input="updateFormErrors()"
      />
    </div>
    <div class="w-full mt-4">
      <CustomInputThree
        v-model="submitDetails.promotion_code"
        type="text"
        name=""
        placeholder="Promo Code"
        :error="submitErrors.promotion_code"
        @input="updateFormErrors()"
      />
    </div>

    <div class="w-full mt-4">
      <CustomCheckbox
        :value="agreedToTerms"
        @change="updateCheck"
      >
      </CustomCheckbox>
      <h6 v-if="!agreedToTerms&&submittedOnce" class="text-red-500 text-base mt-2">Please agree to the Terms and Conditions</h6>
    </div>

    <div class="w-full mt-4">
      <CustomButton
        @onClick="goToTerms"
        bgColor="bg-primary"
        textColor="text-white"
        borderColor="border-primary"
        hoverTextColor="hover:text-kitchen-dark"
        hoverBorderColor="hover:border-primary"
        class="mr-2"
      >
        Terms &amp; Conditions
      </CustomButton>
    </div>

    <div class="w-full mt-4">
      <CustomButton
        @onClick="submitForm"
        textColor="text-primary"
        borderColor="border-primary"
        hoverTextColor="hover:text-kitchen-dark"
        hoverBorderColor="hover:border-kitchen-dark"
        class="mr-2"
      >
        Continue
      </CustomButton>
    </div>
  </div>
</template>

<script>

import CustomInputThree from '@/components/elements/CustomInputThree.vue'
import CustomButton from '@/components/elements/CustomButton.vue'
import CustomCheckbox from '@/components/elements/CustomCheckbox.vue'

export default {
  name: 'SignUpForm',
  components: {
    CustomInputThree,
    CustomButton,
    CustomCheckbox
  },
  props: {
  },
  data () {
    return {
      agreedToTerms: false,
      formHasErrors: false,
      submittedOnce: false,
      submitDetails: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        password: '',
        password_confirmation: '',
        promotion_code: ''
      },
      submitErrors: {
        name: false,
        surname: false,
        phone: false,
        email: false,
        password: false,
        password_confirmation: false,
        promotion_code: false
      }
    }
  },
  computed: {
  },
  methods: {
    goToTerms () {
      let routeData = this.$router.resolve(
        {
          name: 'Terms'
        }
      )
      window.open(routeData.href, '_blank')
    },
    updateCheck (value) {
      this.agreedToTerms = value
    },
    updateFormErrors () {
      this.formHasErrors = false
      if (this.submitDetails.name) this.submitErrors.name = false
      else {
        this.submitErrors.name = true
        this.formHasErrors = true
      }

      if (this.submitDetails.surname) this.submitErrors.surname = false
      else {
        this.submitErrors.surname = true
        this.formHasErrors = true
      }

      if (this.submitDetails.phone) this.submitErrors.phone = false
      else {
        this.submitErrors.phone = true
        this.formHasErrors = true
      }

      if (this.submitDetails.email) this.submitErrors.email = false
      else {
        this.submitErrors.email = true
        this.formHasErrors = true
      }

      if (this.submitDetails.password) this.submitErrors.password = false
      else {
        this.submitErrors.password = true
        this.formHasErrors = true
      }

      if (this.submitDetails.password_confirmation) this.submitErrors.password_confirmation = false
      else {
        this.submitErrors.password_confirmation = true
        this.formHasErrors = true
      }

      if (this.submitDetails.password !== this.submitDetails.password_confirmation) {
        this.submitErrors.password = true
        this.submitErrors.password_confirmation = true
        this.formHasErrors = true
      }

      // if (this.submitDetails.promotion_code) this.submitErrors.promotion_code = false
      // else {
      //   this.submitErrors.promotion_code = true
      //   this.formHasErrors = true
      // }
    },
    submitForm () {
      this.submittedOnce = true
      this.updateFormErrors()
      if (!this.formHasErrors && this.agreedToTerms) this.$emit('onSubmit', this.submitDetails)
    }
  }
}

</script>

<style lang="scss">

</style>
