<template>
  <div
    class="w-full flex items-end"
    :class="[
      backgroundColor,
      {
        'fixed':fixed
      }
    ]"
  >
    <div
      class="w-full flex"
      :class="{
        'justify-around':!leftAligned,
        'justify-around md:justify-start pl-0 md:pl-20':leftAligned,
      }"
    >
      <div
        v-for="(link, index) in links" :key="index"
        class="w-64 h-12 rounded-t-xl flex items-center justify-center transition-all duration-200"
        :class="getClass(link)"
      >
        <p @click="emitIndex(index)" class="cursor-pointer text-xs md:text-sm leading-none uppercase font-medium tracking-widest">{{link.title}}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NavBar',
  components: {
  },
  props: {
    links: Array,
    leftAligned: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: 'bg-gray-500'
    },
    backgroundActiveColor: {
      type: String,
      default: 'bg-white'
    },
    textColor: {
      type: String,
      default: 'text-white'
    },
    textActiveColor: {
      type: String,
      default: 'text-gray-500'
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    getClass (link) {
      if (link.active) {
        return this.backgroundActiveColor + ' ' + this.textActiveColor
      } else {
        return this.backgroundColor + ' ' + this.textColor
      }
    },
    emitIndex (index) {
      this.$emit('onClick', index)
    }
  }
}

</script>

<style lang="scss">

</style>
