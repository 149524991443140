<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 50 50"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M25,0C11.2,0,0,11.2,0,25c0,13.8,11.2,25,25,25s25-11.2,25-25C50,11.2,38.8,0,25,0z M31.9,14.9
      c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2C28.6,16.3,30.1,14.9,31.9,14.9z M18,14.9
      c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2C14.8,16.3,16.2,14.9,18,14.9z M35.4,34.7
      C33.2,37.2,29.1,40,25,40c-3.8,0-8.1-2.6-10.4-5.3c-1-1.2-0.9-3,0.3-4c1.2-1,3-0.9,4,0.3c1.5,1.8,4.3,3.3,6.1,3.3
      c1.8,0,4.5-1.4,6-3.3c1-1.2,2.8-1.4,4-0.3C36.2,31.7,36.4,33.5,35.4,34.7z"/>
  </svg>
</template>

<script>
export default {
  name: 'SmileIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-64'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
