<template>
  <div
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    class="flex cursor-pointer w-full h-40 md:h-80 text-white"
  >
    <div
      :style="{ backgroundImage: 'url(' + imagePath + ')' }"
      @click="$emit('onClick')"
      class="rounded-l-xl w-1/3 bg-cover bg-center bg-no-repeat"
      :class="[
        customCss
      ]"
    >
    </div>

    <div
      @click="$emit('onClick')"
      class="rounded-r-xl w-2/3 p-5 md:p-12 flex flex-wrap content-between"
      :class="[
        customCss
      ]"
    >
      <div class="w-full bg-cover bg-center bg-no-repeat">
        <p class="text-xs md:text-sm uppercase font-medium tracking-widest truncate">{{subTitle}}</p>
        <h3 class="h-16 md:h-24 overflow-hidden leading-tight text-2xl md:text-4xl font-extrabold">{{title}}</h3>
      </div>

      <div class="w-full border-t border-white flex md:pt-4">
        <div class="w-1/3 md:w-1/4 flex items-center h-12">
          <div class="pr-4 md:pr-8">
            <p class="text-xs md:text-sm leading-snug uppercase font-medium tracking-widest">{{firstTitle}}</p>
            <h3 class="text-lg md:text-2xl leading-snug font-extrabold">{{firstValue}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardTwo',
  components: {
  },
  props: {
    title: String,
    subTitle: String,
    firstTitle: String,
    firstValue: [String, Number],
    backgroundColor: {
      type: String,
      default: 'bg-gray-400'
    },
    backgroundHoverColor: {
      type: String,
      default: 'bg-gray-900'
    },
    imagePath: String
  },
  data () {
    return {
      hovered: false
    }
  },
  computed: {
    customCss () {
      var toReturn = ''

      if (!this.hovered) {
        toReturn += this.backgroundColor
      } else {
        toReturn += this.backgroundHoverColor
      }
      return toReturn
    }
  },
  methods: {
  }
}

</script>

<style lang="scss">

</style>
