<template>
  <div class="howto">
    <!-- video modal -->
    <div
      @click="videos[0].videoOpen=false"
      class="z-30 fixed h-screen w-full items-center justify-center transition-all duration-500"
      :class="{
        'videoModalBackground opacity-100 flex':videos[0].videoOpen,
        'opacity-0 hidden':!videos[0].videoOpen,
      }"
    >
      <div v-if="videos[0].videoOpen" class="absolute p-4 md:p-12 w-full text-white rounded-xl">
        <div class="mb-12 md:mb-24">
          <iframe class="w-full" :height="videoHeight" :src="videos[0].videoUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div
      @click="videos[1].videoOpen=false"
      class="z-30 fixed h-screen w-full items-center justify-center transition-all duration-500"
      :class="{
        'videoModalBackground opacity-100 flex':videos[1].videoOpen,
        'opacity-0 hidden':!videos[1].videoOpen,
      }"
    >
      <div v-if="videos[1].videoOpen" class="absolute p-4 md:p-12 w-full text-white rounded-xl">
        <div class="mb-12 md:mb-24">
          <iframe class="w-full" :height="videoHeight" :src="videos[1].videoUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div
      @click="videos[2].videoOpen=false"
      class="z-30 fixed h-screen w-full items-center justify-center transition-all duration-500"
      :class="{
        'videoModalBackground opacity-100 flex':videos[2].videoOpen,
        'opacity-0 hidden':!videos[2].videoOpen,
      }"
    >
      <div v-if="videos[2].videoOpen" class="absolute p-4 md:p-12 w-full text-white rounded-xl">
        <div class="mb-12 md:mb-24">
          <iframe class="w-full" :height="videoHeight" :src="videos[2].videoUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div
      :style="{ backgroundImage: 'url(/img/ept_legends-workout-DSC_0472-c.jpg)' }"
      class="bg-cover bg-top h-screen min-h-screen bg-gray-300 flex flex-col justify-between px-8 py-8 md:px-24 md:py-24"
    >
      <div class="headingLogo w-full flex-1 flex items-center md:items-end md:pb-24">
        <Logo heightClass="lg:h-40" />
      </div>
      <div class="w-full">
        <h1 class="text-5xl md:text-7xl lg:text-9xl uppercase font-extrabold leading-none">Welcome</h1>
        <h1 class="text-5xl md:text-7xl lg:text-9xl uppercase font-extrabold leading-none">to</h1>
        <h1 class="text-5xl md:text-7xl lg:text-9xl uppercase font-extrabold leading-none">the</h1>
        <h1 class="text-5xl md:text-7xl lg:text-9xl uppercase font-extrabold leading-none">team!</h1>
      </div>
    </div>

    <div
      class="bg-black text-white justify-center items-center text-center px-12 lg:px-64 pt-24 pb-56"
    >
      <p class="text-base md:text-3xl uppercase leading-loose tracking-widest">Congratulations on getting started.<br />We're so excited to have you here!</p>
      <p class="mt-12 text-sm md:text-lg leading-loose tracking-widest">To get the most out of the Legends Program, we have put together a Legends Tour. By completing the tour you will have the best members experience and know exactly how to get the most out of the program.</p>
    </div>

    <div
      class="bg-white text-black justify-center items-center text-center px-12 lg:px-12 pb-24 pt-0 flex flex-wrap"
    >
      <div
        :style="{ backgroundImage: 'url(' + videos[0].videoThumbnail + ')' }"
        class="-mt-40 w-96 h-64 relative bg-cover bg-no-repeat bg-center bg-gray-400"
      >
        <div
          @click="videos[0].videoOpen=true"
          class="
          w-full h-full
          flex
          absolute
          top-0
          left-0
          bg-black
          items-center
          justify-center
          cursor-pointer
          opacity-50
          hover:opacity-25
          transition duration-300 ease-in-out
          "
        >
          <div class="text-white">
            <PlayIcon />
          </div>

        </div>
      </div>

      <div class="w-full text-black mt-12 flex flex-wrap justify-center">

        <div class="w-full lg:w-1/2 mt-24">
          <div class="flex flex-wrap justify-center">
            <p class="w-full text-base md:text-lg uppercase leading-loose tracking-widest font-bold">Join the community</p>
            <p class="w-full mt-2 text-base md:text-lg uppercase leading-loose tracking-widest">Click below to join our online private community</p>
            <p class="w-full mt-2 text-base md:text-base leading-loose">Connect with other Legends, join us for weekly live health Q&A’s + Workouts, gain heaps of motivation & support, and stay accountable to your goals.</p>
            <p class="w-full mt-2 text-base md:text-base leading-loose">Feel free to say a little ‘hello’, let us know where in the world you’re tuning in from, why you’ve started the Legends program, and what you hope to get out of it. See you over there shortly!</p>
            <div>
              <CustomButtonTwo
                @onClick="goToCommunity"
                class="mt-12"
                bgColor="bg-white"
                textColor="text-primary"
                borderColor="border-primary"
                hoverTextColor="hover:text-black"
                hoverBorderColor="hover:border-black"
              >
                Community
              </CustomButtonTwo>
            </div>
          </div>
        </div>

        <!-- <div class="w-full lg:w-1/2 mt-24">
          <div class="lg:pl-24 flex flex-wrap justify-center">
            <p class="w-full text-base md:text-lg uppercase leading-loose tracking-widest font-bold">Refer a Friend & Get Paid!</p>
            <p class="w-full mt-2 text-base md:text-lg uppercase leading-loose tracking-widest">When you become an EPT Legend’s affiliate you can recommend our program to family, friends, and your followers. As a thank you, we will continue to pay you for every referral on a monthly basis!</p>
            <p class="w-full mt-2 text-base md:text-base leading-loose">For every member you refer, who gets started, you will earn $7/member every month for as long as they continue training on the program. That’s 1/4 of their monthly subscription, straight in your pocket!</p>
            <p class="w-full mt-2 text-base md:text-base leading-loose">Feel free to say a little ‘hello’, let us know where in the world you’re based, what you’ve started the Legends program for, and what you hope to get out of it. See you over there!</p>
            <p class="w-full mt-2 text-base md:text-base leading-loose">Click below to complete the simple setup and receive your own personalised link to refer our program to your friends and family. Your link will then track anyone who clicks on it and as soon as they get started we pay you!</p>
            <div>
              <CustomButtonTwo
                @onClick="goToGetPaid"
                class="mt-12"
                bgColor="bg-white"
                textColor="text-primary"
                borderColor="border-primary"
                hoverTextColor="hover:text-black"
                hoverBorderColor="hover:border-black"
              >
                Get Paid
              </CustomButtonTwo>
            </div>
          </div>
        </div> -->

      </div>

      <div class="w-full text-black flex flex-wrap justify-center">

        <div class="w-full lg:w-1/2 mt-24">
          <div class="lg:pr-24 lg:border-r lg:border-kitchen flex flex-wrap justify-center">
            <p class="w-full text-base md:text-lg uppercase leading-loose tracking-widest font-bold">take a tour of the site</p>
            <p class="w-full mt-2 text-base md:text-lg uppercase leading-loose tracking-widest">IN THE VIDEO BELOW AND WE WILL WALK YOU THROUGH THE ENTIRE PROGRAM, WHERE TO FIND EVERYTHING, AND HOW TO GET THE MOST OUT THE PROGRAM.</p>

            <div class="w-full flex justify-center mt-12">
              <div
                :style="{ backgroundImage: 'url(' + videos[1].videoThumbnail + ')' }"
                class="w-96 h-64 relative bg-cover bg-no-repeat bg-center bg-gray-400"
              >
                <div
                  @click="videos[1].videoOpen=true"
                  class="
                  w-full h-full
                  flex
                  absolute
                  top-0
                  left-0
                  bg-black
                  items-center
                  justify-center
                  cursor-pointer
                  opacity-50
                  hover:opacity-25
                  transition duration-300 ease-in-out
                  "
                >
                  <div class="text-white">
                    <PlayIcon />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="w-full lg:w-1/2 mt-24">
          <div class="lg:pl-24 flex flex-wrap justify-center">
            <p class="w-full text-base md:text-lg uppercase leading-loose tracking-widest font-bold">Road To Success</p>
            <p class="w-full mt-2 text-base md:text-lg uppercase leading-loose tracking-widest">Getting Started</p>
            <p class="w-full mt-2 text-base md:text-base leading-loose">To kick start your health journey, we have developed a 12 Week Road To Success Program. This will cover all the fundamentals of health to set you up for success with tips, strategies, tools, and educational videos to help you create a healthy lifestyle you can maintain for a lifetime! This program is designed for EVERYONE who starts the Legends Program, it also includes beginner workouts but if you’re more experienced with exercise you have the option to skip those.</p>
            <div>
              <CustomButtonTwo
                @onClick="goToWeek"
                class="mt-12"
                bgColor="bg-white"
                textColor="text-primary"
                borderColor="border-primary"
                hoverTextColor="hover:text-black"
                hoverBorderColor="hover:border-black"
              >
                Begin Week 1
              </CustomButtonTwo>
            </div>

            <div class="w-full flex justify-center mt-12">
              <div
                :style="{ backgroundImage: 'url(' + videos[2].videoThumbnail + ')' }"
                class="w-96 h-64 relative bg-cover bg-no-repeat bg-center bg-gray-400"
              >
                <div
                  @click="videos[2].videoOpen=true"
                  class="
                  w-full h-full
                  flex
                  absolute
                  top-0
                  left-0
                  bg-black
                  items-center
                  justify-center
                  cursor-pointer
                  opacity-50
                  hover:opacity-25
                  transition duration-300 ease-in-out
                  "
                >
                  <div class="text-white">
                    <PlayIcon />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

    <div
      class="bg-primary h-40 flex items-center justify-center"
    >
      <div>
        <CustomButtonTwo
          @onClick="goToProfile"
          bgColor="bg-primary"
          textColor="text-white"
          borderColor="border-white"
          hoverTextColor="hover:text-black"
          hoverBorderColor="hover:border-black"
        >
          I'm ready!
        </CustomButtonTwo>
      </div>
    </div>

    <div class="w-full">
      <Footer />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Logo from '@/components/icons/Logo.vue'
import PlayIcon from '@/components/icons/PlayIcon.vue'
import Footer from '@/components/Footer.vue'
import CustomButtonTwo from '@/components/elements/CustomButtonTwo.vue'

export default {
  name: 'howtwo',
  components: {
    Logo,
    PlayIcon,
    CustomButtonTwo,
    Footer
  },
  data () {
    return {
      videos: [
        {
          videoOpen: false,
          videoUrl: 'https://player.vimeo.com/video/446198094',
          videoThumbnail: '/img/ept-vid-welcome.jpg'
        },
        {
          videoOpen: false,
          videoUrl: 'https://player.vimeo.com/video/448447554',
          videoThumbnail: '/img/ept-vid-tour.jpg'
        },
        {
          videoOpen: false,
          videoUrl: 'https://player.vimeo.com/video/437046043',
          videoThumbnail: '/img/ept-vid-success.jpg'
        }
      ]
    }
  },
  computed: {
    videoHeight () {
      var toReturn = '300'
      if (this.$mq === 'sm') toReturn = '300'
      else if (this.$mq === 'md') toReturn = '400'
      else if (this.$mq === 'lg') toReturn = '400'
      else if (this.$mq === 'xl') toReturn = '400'
      else if (this.$mq === 'xxl') toReturn = '800'
      else if (this.$mq === 'xxxl') toReturn = '800'
      return toReturn
    }
  },
  methods: {
    goToPlan () {
      this.$router.push('/plan')
    },
    goToCommunity () {
      window.open('https://www.facebook.com/groups/206669930619771/', '_blank')
    },
    goToGetPaid () {
      window.open('https://eptlegends.tapfiliate.com/', '_blank')
    },
    goToWeek () {
      window.location = '/profile/2'
    },
    goToProfile () {
      window.location = '/profile/0'
    }
  },
  created () {
  },
  mounted () {
    // if they're logged in and it's the first time they've logged in
    // set the have_logged_in flag
    if (this.$store.getters.token && !this.$store.getters.user.haveLoggedIn) {
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('uuid', this.$store.getters.user.uuid)
      axios.post(process.env.VUE_APP_ROOT_API + '/have-logged-in', updater, authorization).then(response => {

      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    }
  }
}
</script>

<style lang="scss">
  .howto {
    .videoModalBackground {
      background-color: rgba(0, 0, 0, 0.9);
    }

    .bannerOne {
      background-position: 50%  30%;
    }

    .headingLogo {
      padding-bottom: 0px;
    }

    @media (min-height: '1000px') {
      .headingLogo {
        padding-bottom: 100px;
      }
    }
  }

</style>
