<template>
  <div class="signupfinish h-full">
    <div class="w-full bg-primary flex px-8 md:px-24 pb-8 md:pb-24 pt-24">
      <div class="w-full">
        <Logo heightClass="h-8 mb-2" />
          <h3 class="font-extrabold leading-none">finalising.</h3>
      </div>
    </div>
    <div class="px-12 md:px-24 pt-12 md:pt-12 text-primary">
      <div v-if="finished" class="w-full">
        <h3 class="text-xl md:text-3xl text-center text-primary">Successfully registered</h3>
        <div class="flex justify-center">
          <div class="w-24 mt-4">
            <CustomButton
              @onClick="goToProfile"
              bgColor="bg-white"
              textColor="text-primary"
              borderColor="border-primary"
              hoverTextColor="hover:text-black"
              hoverBorderColor="hover:border-black"
            >
              Login
            </CustomButton>
          </div>
        </div>
      </div>
      <div v-if="!finished&&failed" class="w-full">
        <h3 class="text-xl md:text-3xl text-center text-red-500">Registration Failed!</h3>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Logo from '@/components/icons/Logo.vue'
import CustomButton from '@/components/elements/CustomButton.vue'

export default {
  name: 'signupfinish',
  components: {
    Logo,
    CustomButton
  },
  data () {
    return {
      finished: false,
      failed: false
    }
  },
  computed: {
  },
  methods: {
    goToProfile () {
      window.location = '/profile/0'
    },
    submitTap () {
      window.tap(
        'create',
        'acct_1GQn6jIZH9zc1qV7',
        {
          integration: 'stripe'
        }
      )

      window.tap(
        'trial',
        'sub_HkTjhBkT7u3TFb'
        // this.stripeCheckout.customer
      )
    }
  },
  mounted () {
    if (this.$route.params.status === 'success') {
      this.$store.dispatch('setShowLoading', true)

      const updater = new URLSearchParams()
      updater.append('uuid', this.$route.params.uuid)
      // updater.append('session', this.$route.params.session)
      axios.post(process.env.VUE_APP_ROOT_API + '/finish_registration', updater).then(response => {
        this.$store.dispatch('setShowLoading', false)
        if (response.data.user_status === 'SUCCESS') {
          // this.stripeCheckout = response.data.stripe
          this.finished = true
          this.failed = false
          this.submitTap()
        }
      }).catch(error => {
        this.$store.dispatch('setShowLoading', false)
        this.failed = true
        console.log('Error 1', error)
      })
    }
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
