<template>
  <div class="academy h-full">
    <TopBanner title="Academy" imagePath="/img/AdobeStock_327395593_Preview-grey.png" fixed />

    <div class="pt-64 px-6 w-full bg-white text-black flex flex-wrap">
      <div
        v-for="(academyWorkoutGroup, index) in academyWorkoutGroups" :key="index"
        class="w-full md:w-1/2 px-2 md:px-6 py-2 md:py-6"
      >
        <Card
          :title="academyWorkoutGroup.title"
          :subTitle="academyWorkoutGroup.subTitle"
          firstTitle="Modules"
          :firstValue="academyWorkoutGroup.numberOfWorkouts"
          secondTitle="Categories"
          :secondValue="academyWorkoutGroup.numberOfCategories"
          backgroundColor="bg-academy"
          backgroundHoverColor="bg-academy-dark"
          @onClick="goToAcademyWorkoutGroup(academyWorkoutGroup)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBanner from '@/components/TopBanner.vue'
import Card from '@/components/Card.vue'
import axios from 'axios'

export default {
  name: 'academy',
  components: {
    Card,
    TopBanner
  },
  data () {
    return {
      academyWorkoutGroups: []
    }
  },
  computed: {
  },
  methods: {
    goToAcademyWorkoutGroup (academyWorkoutGroup) {
      this.$router.push('/academyworkoutgroup/' + academyWorkoutGroup.id)
    },
    getAcademyWorkoutGroups () {
      this.$store.dispatch('setShowLoading', true)
      var toReturn = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      axios.get(process.env.VUE_APP_ROOT_API + '/acadamy-workout-groups', authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        for (var i = 0; i < data.length; i++) {
          toReturn.push({
            id: data[i].id,
            title: data[i].title,
            subTitle: data[i].sub_title,
            numberOfWorkouts: data[i].numberOfWorkouts,
            numberOfCategories: data[i].numberOfCategories
          })
        }
        this.academyWorkoutGroups = toReturn
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error', error)
      })
    }
  },
  created () {
  },
  mounted () {
    this.getAcademyWorkoutGroups()

    // this.$store.dispatch('getAcademyWorkoutGroups')
  }
}
</script>

<style lang="scss">

</style>
