import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
var ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    user: {},
    token: '',
    loggedInPaths: ['/profile/:tab', '/gym', '/academy', '/kitchen'],
    loggedOutPaths: [],
    navLinks: [
      {
        name: 'Profile',
        path: '/profile/0',
        dotColor: 'text-white'
      },
      {
        name: 'Legends Gym',
        path: '/gym',
        dotColor: 'text-gym'
      },
      {
        name: 'Academy',
        path: '/academy',
        dotColor: 'text-academy'
      },
      {
        name: 'The Kitchen',
        path: '/kitchen',
        dotColor: 'text-kitchen'
      },
      {
        name: 'Blog',
        path: '/blog',
        dotColor: 'text-kitchen-dark'
      }
    ],
    showLoading: false,
    tokenExpired: false
  }
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  state: getDefaultState(),
  getters: {
    user: state => state.user,
    token: state => state.token,
    showLoading: state => state.showLoading,
    tokenExpired: state => state.tokenExpired
  },
  mutations: {
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_SHOW_LOADING: (state, showLoading) => {
      state.showLoading = showLoading
    },
    SET_TOKEN_EXPIRED: (state, tokenExpired) => {
      state.tokenExpired = tokenExpired
    }
  },
  actions: {
    logoutUser ({ commit }) {
      commit('RESET_STATE', '')
    },
    setUser ({ commit }, user) {
      commit('SET_USER', user)
    },
    setToken ({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    setShowLoading ({ commit }, showLoading) {
      commit('SET_SHOW_LOADING', showLoading)
    },
    setTokenExpired ({ commit }, tokenExpired) {
      commit('SET_TOKEN_EXPIRED', tokenExpired)
    }
  },
  modules: {
  }
})
