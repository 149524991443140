<template>
  <svg
    class="LoadingIcon"
    :class="classObject"
    viewBox="0 0 42.9 42.9"
    @click="$emit('onClick')"
  >
    <path class="st0" style="fill: #D3D563;" d="M23,3.1c9,0.7,16.1,7.9,16.9,16.9h3C42.1,9.3,33.6,0.8,23,0.1V3.1z"/>
    <path class="st1" style="fill: #DDB26D;" d="M39.8,22.9c-0.7,9-7.9,16.1-16.9,16.9v3c10.6-0.8,19.1-9.2,19.9-19.9H39.8z"/>
    <path class="st2" style="fill: #C9D8E0;" d="M20,39.8c-9-0.7-16.1-7.9-16.9-16.9h-3C0.8,33.6,9.3,42.1,20,42.8V39.8z"/>
    <path class="st3" style="fill: #4E7480;" d="M3.1,19.9C3.8,11,11,3.8,20,3.1v-3C9.3,0.8,0.8,9.3,0.1,19.9H3.1z"/>
    <!-- <path class="firstBar" d="M9.37,5.53C4.74,7.47 1.82,9.71 0,11.94L0,45.56L9.37,45.56L9.37,5.53Z" style="fill-rule:nonzero;"/>
    <path class="secondBar" d="M26.59,1.28L26.59,45.56L17.08,45.56L17.08,3.05C19.85,2.37 23.01,1.77 26.59,1.28Z" style="fill-rule:nonzero;"/>
    <path class="thirdBar" d="M43.66,0C40.3,0.08 37.19,0.24 34.29,0.47L34.29,45.56L43.66,45.56L43.66,0Z" style="fill-rule:nonzero;"/> -->
  </svg>
</template>

<script>
export default {
  name: 'LoadingIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-6'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
  .LoadingIcon {
    animation: LoadingIconPulse 3s infinite;
    animation-delay: 0s;
  }

  @keyframes LoadingIconPulse {
    0% {
      transform:rotate(0deg);
    }
    30% {
    }
    50% {
      transform:rotate(360deg);
    }
    70% {
    }
    100% {
      transform:rotate(0deg);
    }
  }

</style>
