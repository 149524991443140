<template>
  <div class="profile h-full">
    <div class="w-full bg-primary flex px-8 md:px-24 pb-8 md:pb-24 pt-24">
      <div class="w-full">
        <Logo heightClass="h-8 mb-2" />
          <h3 class="font-extrabold leading-none">say hello.</h3>
      </div>
    </div>
    <div class="px-12 md:px-24 pt-12 md:pt-12 text-primary">
      <p class="tracking-wide">
        EPT Head Office<br />
        23 Forbes Street,<br />
        Devonport TAS 7310<br />
        +61 3 6424 1600<br />
        hello@eptlegends.com<br />
      </p>
      <div class="flex mt-10">
        <FacebookIcon @onClick="goToFacebook" isLink />
        <InstagramIcon class="ml-4" @onClick="goToInstagram" isLink />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/icons/Logo.vue'
import FacebookIcon from '@/components/icons/FacebookIcon.vue'
import InstagramIcon from '@/components/icons/InstagramIcon.vue'

export default {
  name: 'contact',
  components: {
    Logo,
    FacebookIcon,
    InstagramIcon
  },
  data () {
    return {
      product: {},
      messageSent: false,
      messageFailed: false
    }
  },
  computed: {
  },
  methods: {
    goToFacebook () {
      window.open('https://www.facebook.com/Essential-Personal-Training-111345108887580', '_blank')
    },
    goToInstagram () {
      window.open('https://www.instagram.com/essentialpersonaltraining/', '_blank')
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
