<template>
  <div class="kitchen h-full">
    <TopBanner :title="kitchenWorkoutGroup.title" backgroundColor="bg-kitchen" fixed />

    <div class="pt-64 px-6 w-full bg-white text-black flex flex-wrap">
      <div
        v-for="(kitchenWorkoutCategory, index) in kitchenWorkoutGroup.kitchenWorkoutCategories" :key="index"
        class="w-full md:w-1/2 px-2 md:px-6 py-2 md:py-6"
      >
        <CardTwo
          :title="kitchenWorkoutCategory.title"
          :subTitle="kitchenWorkoutGroup.title"
          firstTitle="Recipes"
          :firstValue="kitchenWorkoutCategory.numberOfWorkouts"
          backgroundColor="bg-kitchen"
          backgroundHoverColor="bg-kitchen-dark"
          @onClick="goToKitchenWorkoutCategory(kitchenWorkoutCategory)"
          :imagePath="kitchenWorkoutCategory.imagePath"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBanner from '@/components/TopBanner.vue'
import CardTwo from '@/components/CardTwo.vue'
import axios from 'axios'

export default {
  name: 'kitchenworkoutgroup',
  components: {
    CardTwo,
    TopBanner
  },
  data () {
    return {
      kitchenWorkoutGroup: {}
    }
  },
  computed: {
  },
  methods: {
    getKitchenWorkoutGroup () {
      this.$store.dispatch('setShowLoading', true)
      var toReturn = {}
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('id', this.$route.params.id)
      axios.post(process.env.VUE_APP_ROOT_API + '/kitchen-workout-group', updater, authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        toReturn = {
          id: data.id,
          title: data.title,
          subTitle: data.sub_title,
          numberOfWorkouts: data.numberOfWorkouts,
          numberOfCategories: data.numberOfCategories,
          kitchenWorkoutCategories: []
        }
        for (var i = 0; i < data.workout_categories.length; i++) {
          toReturn.kitchenWorkoutCategories.push({
            id: data.workout_categories[i].id,
            title: data.workout_categories[i].title,
            numberOfWorkouts: data.workout_categories[i].numberOfWorkouts
          })
          if (data.workout_categories[i].image) toReturn.kitchenWorkoutCategories[i].imagePath = data.workout_categories[i].image.path
        }

        this.kitchenWorkoutGroup = toReturn
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error', error)
      })
    },
    goToKitchenWorkoutCategory (kitchenWorkoutCategory) {
      this.$router.push('/kitchenworkoutcategory/' + kitchenWorkoutCategory.id)
      // window.location.href = '/kitchenworkoutcategory/' + kitchenWorkoutCategory.id
    }
  },
  mounted () {
    this.getKitchenWorkoutGroup()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
