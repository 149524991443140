<template>
  <div class="gym h-full">
    <TopBanner :title="gymWorkoutGroup.title" backgroundColor="bg-gym" fixed />

    <div class="pt-64 px-6 w-full bg-white text-black flex flex-wrap">
      <div
        v-for="(gymWorkoutCategory, index) in gymWorkoutGroup.gymWorkoutCategories" :key="index"
        class="w-full md:w-1/2 px-2 md:px-6 py-2 md:py-6"
      >
        <CardTwo
          :title="gymWorkoutCategory.title"
          :subTitle="gymWorkoutGroup.title"
          firstTitle="Workouts"
          :firstValue="gymWorkoutCategory.numberOfWorkouts"
          backgroundColor="bg-gym"
          backgroundHoverColor="bg-gym-dark"
          @onClick="goToGymWorkoutCategory(gymWorkoutCategory)"
          :imagePath="gymWorkoutCategory.imagePath"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBanner from '@/components/TopBanner.vue'
import CardTwo from '@/components/CardTwo.vue'
import axios from 'axios'

export default {
  name: 'gymworkoutgroup',
  components: {
    CardTwo,
    TopBanner
  },
  data () {
    return {
      gymWorkoutGroup: {}
    }
  },
  computed: {
  },
  methods: {
    getGymWorkoutGroup () {
      this.$store.dispatch('setShowLoading', true)
      var toReturn = {}
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('id', this.$route.params.id)
      axios.post(process.env.VUE_APP_ROOT_API + '/gym-workout-group', updater, authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        toReturn = {
          id: data.id,
          title: data.title,
          subTitle: data.sub_title,
          numberOfWorkouts: data.numberOfWorkouts,
          numberOfCategories: data.numberOfCategories,
          gymWorkoutCategories: []
        }
        for (var i = 0; i < data.workout_categories.length; i++) {
          toReturn.gymWorkoutCategories.push({
            id: data.workout_categories[i].id,
            title: data.workout_categories[i].title,
            numberOfWorkouts: data.workout_categories[i].numberOfWorkouts
          })
          if (data.workout_categories[i].image) toReturn.gymWorkoutCategories[i].imagePath = data.workout_categories[i].image.path
        }

        this.gymWorkoutGroup = toReturn
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error', error)
      })
    },
    goToGymWorkoutCategory (gymWorkoutCategory) {
      this.$router.push('/gymworkoutcategory/' + gymWorkoutCategory.id)
      // window.location.href = '/gymworkoutcategory/' + gymWorkoutCategory.id
    }
  },
  mounted () {
    this.getGymWorkoutGroup()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
