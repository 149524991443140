import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import SignUp from '../views/SignUp.vue'
import SignUpFinish from '../views/SignUpFinish.vue'
import Plan from '../views/Plan.vue'
import Contact from '../views/Contact.vue'
import Terms from '../views/Terms.vue'

import Gym from '../views/Gym.vue'
import GymWorkoutGroup from '../views/GymWorkoutGroup.vue'
import GymWorkoutCategory from '../views/GymWorkoutCategory.vue'
import GymWorkout from '../views/GymWorkout.vue'

import Academy from '../views/Academy.vue'
import AcademyWorkoutGroup from '../views/AcademyWorkoutGroup.vue'
import AcademyWorkoutCategory from '../views/AcademyWorkoutCategory.vue'
import AcademyWorkout from '../views/AcademyWorkout.vue'

import Kitchen from '../views/Kitchen.vue'
import KitchenWorkoutGroup from '../views/KitchenWorkoutGroup.vue'
import KitchenWorkoutCategory from '../views/KitchenWorkoutCategory.vue'
import KitchenWorkout from '../views/KitchenWorkout.vue'

import Profile from '../views/Profile.vue'
import HowTo from '../views/HowTo.vue'

import Blog from '../views/Blog.vue'
import BlogPost from '../views/BlogPost.vue'

import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/signupfinish/:status/:uuid',
    name: 'SignUpFinish',
    component: SignUpFinish
  },
  {
    path: '/plan',
    name: 'Plan',
    component: Plan
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/profile/:tab',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/gym',
    name: 'Legends Gym',
    component: Gym
  },
  {
    path: '/gymworkoutgroup/:id',
    name: 'Gym Workout Group',
    component: GymWorkoutGroup
  },
  {
    path: '/gymworkoutcategory/:id',
    name: 'Gym Workout Category',
    component: GymWorkoutCategory
  },
  {
    path: '/gymworkout/:groupid/:workoutid',
    name: 'Gym Workout',
    component: GymWorkout
  },
  {
    path: '/academy',
    name: 'Academy',
    component: Academy
  },
  {
    path: '/academyworkoutgroup/:id',
    name: 'Academy Workout Group',
    component: AcademyWorkoutGroup
  },
  {
    path: '/academyworkoutcategory/:id',
    name: 'Academy Workout Category',
    component: AcademyWorkoutCategory
  },
  {
    path: '/academyworkout/:groupid/:workoutid',
    name: 'Academy Workout',
    component: AcademyWorkout
  },
  {
    path: '/kitchen',
    name: 'The Kitchen',
    component: Kitchen
  },
  {
    path: '/kitchenworkoutgroup/:id',
    name: 'Kitchen Workout Group',
    component: KitchenWorkoutGroup
  },
  {
    path: '/kitchenworkoutcategory/:id',
    name: 'Kitchen Workout Category',
    component: KitchenWorkoutCategory
  },
  {
    path: '/kitchenworkout/:groupid/:workoutid',
    name: 'Kitchen Workout',
    component: KitchenWorkout
  },
  {
    path: '/howto',
    name: 'How To',
    component: HowTo
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blogpost/:postid',
    name: 'Blog Post',
    component: BlogPost
  },
  {
    path: '/search/:type',
    name: 'Search',
    component: Search
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    return { x: 0, y: 0 }
  }
})

export default router
