<template>
  <div class="kitchen h-full">
    <TopBanner title="Search" backgroundColor="bg-kitchen" fixed />

    <div class="pt-64 px-6 w-full bg-white text-black flex flex-wrap">
      <div
        class="w-full lg:w-1/4 px-2 md:px-4 py-2 md:py-6"
      >
        <div
          class="flex-col justify-evenly  text-white flex flex-wrap items-center border-2 border-kitchen rounded-xl w-full h-72 md:h-80"
        >
          <div
            class="bg-white flex-1 w-full rounded-t-xl bg-cover bg-center bg-no-repeat flex justify-end items-end"
          >
            <div class="w-full">
              <CustomInputThree
                v-model="searchQuery"
                type="text"
                name=""
                placeholder="Type a Recipe name, or tag"
              />
            </div>
          </div>

          <div
            @click="getSearchResults"
            class="cursor-pointer w-full rounded-b-xl p-4 md:p-6 flex flex-wrap items-betwen bg-kitchen hover:bg-kitchen-dark"
          >
            <div class="w-full">
              <div class="w-full bg-cover bg-center bg-no-repeat">
                <p class="text-xs md:text-sm uppercase font-medium tracking-widest truncate">Search for Recipes</p>
                <h3 class="h-15 md:h-13 lg:h-14 overflow-hidden text-lg md:text-xl lg:text-2xl font-extrabold leading-none">Search</h3>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div
        v-for="(kitchenWorkout, index) in kitchenResults" :key="index"
        class="w-1/2 md:w-1/3 lg:w-1/4 px-2 md:px-4 py-2 md:py-6"
      >
        <CardThree
          :title="kitchenWorkout.title"
          :subTitle="kitchenWorkout.categories[0].title"
          firstTitle="Time"
          :firstValue="kitchenWorkout.time"
          secondTitle="Calories"
          :secondValue="kitchenWorkout.kcal"
          :secondValueIsRating="false"
          backgroundColor="bg-kitchen"
          backgroundHoverColor="bg-kitchen-dark"
          :imagePath="kitchenWorkout.imagePath"
          @onClick="goToWorkout(kitchenWorkout)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBanner from '@/components/TopBanner.vue'
import CustomInputThree from '@/components/elements/CustomInputThree.vue'
import CardThree from '@/components/CardThree.vue'
import axios from 'axios'

export default {
  name: 'search',
  components: {
    CustomInputThree,
    CardThree,
    TopBanner
  },
  data () {
    return {
      searchQuery: '',
      kitchenResults: []
    }
  },
  computed: {
  },
  methods: {
    getSearchResults () {
      this.$store.dispatch('setShowLoading', true)
      var toReturn = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('searchQuery', this.searchQuery)
      axios.post(process.env.VUE_APP_ROOT_API + '/search_kitchen_all', updater, authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        console.log(data)
        for (var i = 0; i < data.length; i++) {
          toReturn.push({
            id: data[i].id,
            title: data[i].title,
            categories: data[i].categories,
            kcal: data[i].kcal,
            time: data[i].time
          })
          if (data[i].image) toReturn[toReturn.length - 1].imagePath = data[i].image.path
        }
        this.kitchenResults = toReturn
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error', error)
      })
    },
    goToWorkout (workout) {
      if (this.$route.params.type === 'kitchen') {
        this.$router.push('/kitchenworkout/' + workout.categories[0].id + '/' + workout.id)
      }
    }
  },
  mounted () {
    this.getSearchResults()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
