<template>
  <svg
    class="TrailIcon"
    :class="classObject"
    viewBox="0 0 152 110.7"
    @click="$emit('onClick')"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <g v-if="!hovered">
      <path class="st0" d="M152,56.3H0V0h152V56.3z M2,54.3h148V2H2V54.3z"/>
      <polygon class="st0" points="112.2,110.7 76,102.3 39.8,110.7 39.8,55.3 112.2,55.3"/>
      <g>
        <path class="st0" d="M31.6,20.1v6.3h11v4.4h-11v8.7h-5.5V15.6h18V20H31.6V20.1z"/>
        <path class="st0" d="M66.1,39.5l-4.6-6.6h-0.3h-4.8v6.6H51V15.6h10.3c2.1,0,3.9,0.4,5.5,1.1s2.8,1.7,3.6,3
          c0.8,1.3,1.3,2.8,1.3,4.6s-0.4,3.3-1.3,4.6c-0.9,1.3-2.1,2.3-3.6,2.9l5.3,7.7H66.1z M64.8,21.2c-0.9-0.7-2.1-1.1-3.8-1.1h-4.5v8.3
          H61c1.7,0,2.9-0.4,3.8-1.1s1.3-1.7,1.3-3.1C66,23,65.6,21.9,64.8,21.2z"/>
        <path class="st0" d="M97.6,35.1v4.4H79.1V15.6h18V20H84.6v5.2h11.1v4.3H84.6V35L97.6,35.1L97.6,35.1z"/>
        <path class="st0" d="M123.8,35.1v4.4h-18.5V15.6h18V20h-12.5v5.2h11.1v4.3h-11.1V35L123.8,35.1L123.8,35.1z"/>
      </g>
      <g>
        <path class="st1" d="M61.6,72.5l-4.5,10.4h-2.4l-4.5-10.4h2.6l3.2,7.4l3.2-7.4H61.6z"/>
        <path class="st1" d="M64.8,72.5h2.4v10.4h-2.4V72.5z"/>
        <path class="st1" d="M79.7,80.9v1.9h-8V72.5h7.8v1.9h-5.4v2.2h4.8v1.9h-4.8v2.4H79.7z"/>
        <path class="st1" d="M99.3,72.5l-3.4,10.4h-2.6l-2.3-7l-2.4,7H86l-3.4-10.4h2.5l2.3,7.3l2.4-7.3H92l2.4,7.3l2.4-7.3H99.3z"/>
      </g>
    </g>

    <g v-if="hovered">
      <path class="st0" d="M152,56.3H0V0h152V56.3z M2,54.3h148V2H2V54.3z"/>
      <polygon class="st0" points="112.2,110.7 76,102.3 39.8,110.7 39.8,55.3 112.2,55.3"/>
      <g>
        <path class="st0" d="M32.4,20.9v5.2h10.5v5.2H32.4v8.2h-6.7V15.6h18.7v5.2H32.4z"/>
        <path class="st0" d="M61.3,33.2h-3.7v6.3h-6.7V15.6h10.9c2.2,0,4,0.4,5.6,1.1c1.6,0.7,2.8,1.7,3.7,3.1c0.9,1.3,1.3,2.9,1.3,4.7
          c0,1.7-0.4,3.2-1.2,4.5c-0.8,1.3-2,2.3-3.5,3l5.1,7.5h-7.2L61.3,33.2z M64.5,21.9c-0.7-0.6-1.7-0.9-3.1-0.9h-3.7v7h3.7
          c1.4,0,2.4-0.3,3.1-0.9c0.7-0.6,1.1-1.5,1.1-2.6C65.6,23.4,65.2,22.5,64.5,21.9z"/>
        <path class="st0" d="M98.6,34.3v5.2H79.5V15.6h18.7v5.2h-12v4h10.6v5H86.1v4.4H98.6z"/>
        <path class="st0" d="M125,34.3v5.2h-19.1V15.6h18.7v5.2h-12v4h10.6v5h-10.6v4.4H125z"/>
      </g>
      <g>
        <path class="st1" d="M61.5,72.5l-4.4,10.4h-2.9l-4.4-10.4h3.2l2.8,6.7l2.9-6.7H61.5z"/>
        <path class="st1" d="M64.5,72.5h2.9v10.4h-2.9V72.5z"/>
        <path class="st1" d="M79.9,80.6v2.3h-8.3V72.5h8.1v2.3h-5.2v1.7h4.6v2.2h-4.6v1.9H79.9z"/>
        <path class="st1" d="M99.9,72.5l-3.4,10.4h-3.1l-2-6.3l-2.1,6.3h-3.1l-3.4-10.4h3l2.1,6.6l2.2-6.6h2.7l2.1,6.7l2.2-6.7H99.9z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TrialIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-8'
    }
  },
  data () {
    return {
      hovered: false
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
  .TrailIcon {
    .st0
    {
      fill:#FFFFFF;
    }
    .st1
    {
      fill:#4D7480;
    }
  }
</style>
