<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 283.5 283.5"
    @click="$emit('onClick')"
  >
    <g>
      <path class="st0" d="M283.5,141.8c0,16.2,0.2,32.4-0.1,48.6c-0.3,15.1-1.3,30.2-7.1,44.5c-9.3,22.8-25.9,37.3-49.5,44.1
        c-14.4,4.2-29.2,4.4-44,4.5c-29.9,0.1-59.8,0.2-89.7-0.1c-15.1-0.1-30.2-1.3-44.5-7.1c-22.6-9.3-37.1-25.8-44-49.2
        c-4.1-14-4.5-28.5-4.5-43C0,153.7,0,123.4,0.3,93c0.1-15,1.3-30,7-44.2C16.6,26,33.3,11.4,57,4.6c14.3-4.1,29-4.4,43.6-4.5
        c29.9-0.1,59.8-0.2,89.7,0.1c15.1,0.1,30.2,1.2,44.5,7.1c22.8,9.3,37.3,25.9,44.2,49.4c4.4,15,4.4,30.5,4.6,45.9
        c0.2,13,0,26.1,0,39.1C283.6,141.8,283.5,141.8,283.5,141.8z M141.8,257.1L141.8,257.1c11.6,0.1,23.3,0.1,34.9,0.1
        c11.8-0.1,23.7-0.4,35.4-2c21.6-3,36.6-16.5,41.9-37.5c2.7-10.8,2.9-22,3-33c0.3-26,0.2-52,0.1-77.9c0-11.7-0.4-23.5-2-35.1
        c-3.1-22.5-17.7-38-40-42.5c-10.2-2.1-20.5-2.4-30.8-2.5c-25.9-0.3-51.7-0.2-77.6-0.1c-11.7,0-23.5,0.4-35.1,2
        C50,31.4,35,44.8,29.6,66.1c-2.7,10.8-2.8,22-3,33c-0.3,25.9-0.2,51.7-0.1,77.6c0,11.8,0.2,23.7,2,35.4c3.2,20,13.7,34.2,33.4,40.7
        c11.6,3.8,23.6,3.8,35.5,4.1C112.2,257.4,127,257.1,141.8,257.1z"/>
      <path class="st0" d="M141.9,215c-40.3,0-73.2-32.8-73.2-73c0-40.5,32.8-73.4,73.2-73.4s73.3,32.9,73.2,73.3
        C215,182.1,182.1,215,141.9,215z M188.6,141.9c0-25.6-21.1-46.8-46.7-46.8s-46.8,21-46.8,46.7c-0.1,25.6,21,46.8,46.7,46.8
        C167.4,188.6,188.6,167.5,188.6,141.9z"/>
      <path class="st0" d="M234.7,66.5c-0.1,9.4-7.9,17.2-17.2,17.3c-9.5,0-17.7-8.1-17.7-17.6c0-9.6,8-17.3,17.9-17.2
        C227.4,49,234.8,56.6,234.7,66.5z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InstagramIcon',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-8'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
