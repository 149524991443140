<template>
  <div id="app" class="h-full text-white">
    <LoginPage v-if="!this.$store.getters.token&&!pageOutsideMemberArea" />
    <transition name="fadeQuick">
      <div
        v-if="this.$store.getters.showLoading"
        class="fixed w-full h-full top-0 left-0 bg-white text-black z-50 flex flex-wrap justify-center items-center"
      >
        <div class="w-full flex flex-wrap justify-center">
          <div class="w-full flex justify-center">
            <LoadingIcon heightClass="h-24" />
          </div>
        </div>
      </div>
    </transition>
    <div
        v-if="this.$store.getters.tokenExpired"
        class="fixed w-full h-full top-0 left-0 bg-primary bg-opacity-75 z-50 flex flex-wrap justify-center items-center"
    >
        <div class="w-full flex flex-wrap justify-center">
            <div class="w-full flex flex-wrap justify-center text-white text-center">
                <h3 class="text-white">Your session has expired, please login again</h3>
                <div class="flex w-full justify-center mt-10">
                  <div>
                    <CustomButton
                      @onClick="logoutUser(true)"
                      textColor="text-white"
                      borderColor="border-white"
                      hoverTextColor="hover:text-primary"
                      hoverBorderColor="hover:border-primary"
                      class="mr-2"
                    >
                      Login
                    </CustomButton>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <NavPage
      :iconColor="navIconColor"
      backgroundColor="bg-primary"
      fixed
    />
    <div v-if="this.$store.getters.token||pageOutsideMemberArea">
      <router-view/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginPage from '@/components/LoginPage.vue'
import NavPage from '@/components/NavPage.vue'
import LoadingIcon from '@/components/icons/LoadingIcon.vue'
import CustomButton from '@/components/elements/CustomButton.vue'

export default {
  name: 'app',
  components: {
    LoginPage,
    NavPage,
    LoadingIcon,
    CustomButton
  },
  metaInfo () {
    return {
      title: 'Essential Personal Training - ' + this.$route.name,
      meta: [
        {
          name: 'description',
          content: 'ARE YOU SICK OF NOT FEELING 100%?? How would you like to...- Feel better than ever before? - Get fit, strong or lose weight? - Be held accountable to achieving you goals? '
        },
        {
          name: 'keywords',
          content: 'ept,essential,personal,training,essential personal training,tasmania pt,pt,tasmanian personal trainers,tasmanian personal training,tasmania training,tasmania trainers,tasmanian trainers,tasmania trainers'
        }
      ]
    }
  },
  data () {
    return {
      pageOutsideMemberArea: false
    }
  },
  computed: {
    // pageOutsideMemberArea () {
    //   console.log('this.$router.currentRoute.path', this.$router.currentRoute.path)
    //   if (this.$router.currentRoute.path === '/login' || this.$router.currentRoute.path === '/signup' || this.$router.currentRoute.path === '/' || this.$router.currentRoute.path === '/plan') return true
    //   else return false
    // },
    navIconColor () {
      var toReturn = 'text-white'
      return toReturn
    }
  },
  methods: {
    logoutUser (expired = false) {
      this.$store.dispatch('setTokenExpired', false)
      this.$store.dispatch('logoutUser', '')
      this.showingPage = false

      if (expired) window.location = '/profile/0'
      else window.location = '/'
    }
  },
  mounted () {
    if (this.$router.currentRoute.path === '/login' || this.$router.currentRoute.path === '/signup' || this.$router.currentRoute.path.includes('signupfinish') || this.$router.currentRoute.path === '/' || this.$router.currentRoute.path === '/plan' || this.$router.currentRoute.path === '/contact' || this.$router.currentRoute.path === '/terms' || this.$router.currentRoute.path === '/blog' || this.$router.currentRoute.path.includes('blogpost')) this.pageOutsideMemberArea = true
    else this.pageOutsideMemberArea = false

    if (!this.$router.currentRoute.path.includes('signupfinish')) {
      window.tap(
        'create',
        '15146-6a5c78',
        {
          integration: 'stripe'
        }
      )
      window.tap('detect')
    }

    // if (!this.$store.getters.token) {
    //   if (this.$router.currentRoute.path !== '/login' && this.$router.currentRoute.path !== '/signup' && this.$router.currentRoute.path !== '/' && this.$router.currentRoute.path !== '/plan') this.$router.push('/login')
    // }
  },
  created () {
    this.$store.dispatch('setShowLoading', false)
  }
}
</script>

<style lang="scss">

  .fade-enter-active {
    transition: opacity .5s
  }
  .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter, .fade-leave-to {
    opacity: 0
  }

  .fadeQuick-enter-active {
    transition: opacity .25s
  }
  .fadeQuick-leave-active {
    transition: opacity .25s
  }
  .fadeQuick-enter, .fadeQuick-leave-to {
    opacity: 0
  }

  .fadeSlow-enter-active {
    transition: opacity 3s
  }
  .fadeSlow-leave-active {
    transition: opacity 3s
  }
  .fadeSlow-enter, .fadeSlow-leave-to {
    opacity: 0
  }

  .fadeInSlow-enter-active {
    transition: opacity 3s
  }
  .fadeInSlow-leave-active {
    transition: opacity 0s
  }
  .fadeInSlow-enter, .fadeInSlow-leave-to {
    opacity: 0
  }

</style>
