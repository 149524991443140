<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 50 59.3"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M25,53c-6.7,0-12.9-2.4-17.8-6.3v12.6l17.5-3.9l17.5,3.9V47.1C37.5,50.8,31.5,53,25,53z"/>
    <path class="st0" d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25c13.8,0,25-11.2,25-25S38.8,0,25,0z M36.4,32.6h-3.5v4.5h-6.5v-4.5H13.8
      v-4.4l10.6-14.8h6.9l-9.6,13.8h4.8v-4h6.3v4h3.5V32.6z"/>
  </svg>
</template>

<script>
export default {
  name: 'BadgeIcon400',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-64'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
