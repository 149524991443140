<template>
  <span class="CustomInputThree">
    <label class="formLabel">
      {{ name }}
    </label>
    <input
      class="text-sm text-primary border-b border-primary focus:shadow-outline py-2 px-4 block w-full appearance-none leading-normal placeholder-primary placeholder-opacity-100"
      :class="{
        'bg-gray-400': disabled,
        'hover:bg-gray-100 bg-white':!disabled,
        'border border-red-500 ':error,
      }"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
      @input="updateValue($event.target.value)"
      @keypress="checkType($event)"
    >
    <p v-if="error" class="text-red-500 text-xs italic py-2">{{ errorMsg }}</p>
  </span>
</template>

<script>
export default {
  name: 'CustomInputThree',
  props: {
    name: String,
    value: [String, Number],
    type: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: 'Required'
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
    checkType (evt) {
      if (this.type === 'number') {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      }
    }
  }
}
</script>

<style lang="scss">
  // hide the number arrows
  .CustomInput
  {
    /* Chrome, Safari, Edge, Opera */
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
</style>
