<template>
  <div class="gym h-full">
    <TopBanner :title="gymWorkoutCategory.title" backgroundColor="bg-gym" fixed />

    <div class="pt-64 px-6 w-full bg-white text-black flex flex-wrap">
      <div
        v-for="(gymWorkout, index) in gymWorkoutCategory.gymWorkouts" :key="index"
        class="w-1/2 md:w-1/3 lg:w-1/4 px-2 md:px-4 py-2 md:py-6"
      >
        <CardThree
          :title="gymWorkout.title"
          :subTitle="gymWorkoutCategory.title"
          firstTitle="Time"
          :firstValue="gymWorkout.time"
          secondTitle="Effort"
          :secondValue="gymWorkout.effort"
          backgroundColor="bg-gym"
          backgroundHoverColor="bg-gym-dark"
          :imagePath="gymWorkout.imagePath"
          @onClick="goToGymWorkout(gymWorkout)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBanner from '@/components/TopBanner.vue'
import CardThree from '@/components/CardThree.vue'
import axios from 'axios'

export default {
  name: 'gymworkoutcategory',
  components: {
    CardThree,
    TopBanner
  },
  data () {
    return {
      gymWorkoutCategory: {}
    }
  },
  computed: {
  },
  methods: {
    getGymWorkoutCategory () {
      this.$store.dispatch('setShowLoading', true)
      var toReturn = {}
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      const updater = new URLSearchParams()
      updater.append('id', this.$route.params.id)
      axios.post(process.env.VUE_APP_ROOT_API + '/gym-workout-category', updater, authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        toReturn = {
          id: data.id,
          title: data.title,
          gymWorkouts: []
        }
        for (var i = 0; i < data.workouts.length; i++) {
          toReturn.gymWorkouts.push({
            id: data.workouts[i].id,
            title: data.workouts[i].title,
            effort: data.workouts[i].effort,
            time: data.workouts[i].time
          })
          if (data.workouts[i].image) toReturn.gymWorkouts[i].imagePath = data.workouts[i].image.path
        }
        this.gymWorkoutCategory = toReturn
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error', error)
      })
    },
    goToGymWorkout (gymWorkout) {
      this.$router.push('/gymworkout/' + this.$route.params.id + '/' + gymWorkout.id)
    }
  },
  mounted () {
    this.getGymWorkoutCategory()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
