import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/main.scss'
import VueMq from 'vue-mq'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// var VueScrollTo = require('vue-scrollto')
// Vue.use(VueScrollTo, {
//   container: 'body',
//   duration: 500,
//   easing: 'ease',
//   offset: -200,
//   force: true,
//   cancelable: true,
//   onStart: false,
//   onDone: false,
//   onCancel: false,
//   x: false,
//   y: true
// })

Vue.use(VueMq, {
  breakpoints: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1920,
    xxxl: Infinity
  }
})

Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyAI4kGa4HBT-9WHUcty2A8SMZ5FMFHg4EU', // *** MY API KEY
    key: 'AIzaSyBMSdXffcHHgEpyTjs11bLOtcBp1sy-mEQ',
    libraries: 'places'
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
