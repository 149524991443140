<template>
  <div class="w-full">
    <div class="w-full flex flex-wrap mt-4">

      <div class="w-full">
        <CustomInputThree
          v-model="submitDetails.name"
          type="text"
          name=""
          placeholder="First Name"
          :error="submitErrors.name"
          @input="updateFormErrors()"
        />

        <CustomInputThree
          v-model="submitDetails.surname"
          type="text"
          name=""
          placeholder="Last Name"
          :error="submitErrors.surname"
          @input="updateFormErrors()"
        />

        <CustomInputThree
          v-model="submitDetails.phone"
          type="text"
          name=""
          placeholder="Phone"
          :error="submitErrors.phone"
          @input="updateFormErrors()"
        />
      </div>

    </div>
    <div class="w-full mt-4">
      <CustomButton @onClick="submitForm" bgColor="bg-primary">Submit</CustomButton>
    </div>
  </div>
</template>

<script>

import CustomInputThree from '@/components/elements/CustomInputThree.vue'
import CustomButton from '@/components/elements/CustomButton.vue'

export default {
  name: 'ProfileEditForm',
  components: {
    CustomInputThree,
    CustomButton
  },
  props: {
  },
  data () {
    return {
      formHasErrors: false,
      submittedOnce: false,
      submitDetails: {
        name: '',
        surname: '',
        phone: ''
      },
      submitErrors: {
        name: false,
        surname: false,
        phone: false
      }
    }
  },
  computed: {
  },
  methods: {
    updateFormErrors () {
      this.formHasErrors = false

      if (this.submitDetails.name) this.submitErrors.name = false
      else {
        this.submitErrors.name = true
        this.formHasErrors = true
      }

      if (this.submitDetails.surname) this.submitErrors.surname = false
      else {
        this.submitErrors.surname = true
        this.formHasErrors = true
      }

      if (this.submitDetails.phone) this.submitErrors.phone = false
      else {
        this.submitErrors.phone = true
        this.formHasErrors = true
      }
    },
    submitForm () {
      this.submittedOnce = true
      this.updateFormErrors()
      if (!this.formHasErrors) this.$emit('onSubmit', this.submitDetails)
    }
  }
}

</script>

<style lang="scss">

</style>
