<template>
  <button
    class="w-full inline-flex justify-center items-center focus:outline-none py-3 px-3 text-sm font-bold transition duration-200 ease-in-out uppercase rounded-full"
    :class="customCss"
    :disabled="disabled"
    :type="type"
    @click="$emit('onClick')"
  >
    <slot>
      CustomButton
    </slot>
  </button>
</template>

<script>
export default {
  name: 'CustomButtm',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    bgColor: String,
    textColor: String,
    borderColor: String,
    hoverTextColor: String,
    hoverBorderColor: String
  },
  computed: {
    customCss: function () {
      var toReturn = ''
      // border
      toReturn += ' border-2 '
      if (this.borderColor) toReturn += ' ' + this.borderColor + ' '
      else {
        if (this.dark) toReturn += ' border-black '
        else toReturn += ' border-white '
      }
      // opacity
      // toReturn += ' opacity-100 '
      // shadow
      // toReturn += ' shadow-md '
      // color
      if (this.textColor) toReturn += ' ' + this.textColor + ' '

      else {
        if (this.dark) toReturn += ' text-black '
        else toReturn += ' text-white '
      }
      // background color
      if (this.bgColor) toReturn += ' ' + this.bgColor + ' '
      else {
        if (this.dark) toReturn += ' bg-transparent '
        else toReturn += ' bg-transparent '
      }

      // border
      // toReturn += ' border-t-2 '

      if (!this.disabled) {
        // HOVER ====================================
        // opacity
        // toReturn += ' hover:opacity-75 '
        // shadow
        // toReturn += ' hover:shadow '
        // color
        toReturn += ' ' + this.hoverTextColor + '  '
        // border
        toReturn += ' ' + this.hoverBorderColor + '  '

        // FOCUS ====================================
        // opacity
        // toReturn += ' focus:opacity-100 '
        // shadow
        // toReturn += ' focus:shadow-inner '
        // color
        // toReturn += ' focus:text-gray-900 '
        // border
      } else {
        toReturn += ' cursor-default opacity-50'
      }
      return toReturn
    }
  }
}
</script>
