<template>
  <div @click="$emit('onClick')" class="cursor-pointer uppercase border-t border-b px-1 md:px-4 py-2 md:py-3">
    <div class="text-xs md:text-sm font-semibold text-center">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavFooterButton'
}
</script>

<style lang="scss">
</style>
