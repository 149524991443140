<template>
  <div class="kitchen h-full">
    <TopBanner title="The Kitchen" imagePath="/img/AdobeStock_327395593_Preview-grey.png" fixed />

    <div class="pt-64 px-6 w-full bg-white text-black flex flex-wrap">

      <div
        class="w-full md:w-1/2 px-2 md:px-6 py-2 md:py-6"
      >
        <div
          class="flex-col justify-evenly  text-white flex flex-wrap items-center border-2 border-kitchen rounded-xl w-full h-72 md:h-80"
        >
          <div
            class="bg-white flex-1 w-full rounded-t-xl bg-cover bg-center bg-no-repeat flex justify-end items-end"
          >
            <!-- <div class="w-full">
              <CustomInputThree
                v-model="searchQuery"
                type="text"
                name=""
                placeholder="Type a Recipe name, or tag"
              />
            </div> -->
          </div>

          <div
            @click="searchRecipes"
            class="cursor-pointer w-full rounded-b-xl p-4 md:p-6 flex flex-wrap items-betwen bg-kitchen hover:bg-kitchen-dark"
          >
            <div class="w-full">
              <div class="w-full bg-cover bg-center bg-no-repeat">
                <p class="text-xs md:text-sm uppercase font-medium tracking-widest truncate">Search for Recipes</p>
                <h3 class="h-15 md:h-13 lg:h-14 overflow-hidden text-lg md:text-xl lg:text-2xl font-extrabold leading-none">Search</h3>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div
        v-for="(kitchenWorkoutGroup, index) in kitchenWorkoutGroups" :key="index"
        class="w-full md:w-1/2 px-2 md:px-6 py-2 md:py-6"
      >
        <Card
          :title="kitchenWorkoutGroup.title"
          :subTitle="kitchenWorkoutGroup.subTitle"
          firstTitle="Recipes"
          :firstValue="kitchenWorkoutGroup.numberOfWorkouts"
          secondTitle="Categories"
          :secondValue="kitchenWorkoutGroup.numberOfCategories"
          backgroundColor="bg-kitchen"
          backgroundHoverColor="bg-kitchen-dark"
          @onClick="goToKitchenWorkoutGroup(kitchenWorkoutGroup)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBanner from '@/components/TopBanner.vue'
import Card from '@/components/Card.vue'
// import CustomInputThree from '@/components/elements/CustomInputThree.vue'
import axios from 'axios'

export default {
  name: 'kitchen',
  components: {
    Card,
    TopBanner
    // CustomInputThree
  },
  data () {
    return {
      searchQuery: '',
      kitchenWorkoutGroups: []
    }
  },
  computed: {
  },
  methods: {
    searchRecipes () {
      this.$router.push('/search/kitchen')
      // this.$store.dispatch('setShowLoading', true)
      // var toReturn = []
      // const authorization = {
      //   headers: {
      //     'Authorization': 'Bearer ' + this.$store.getters.token
      //   }
      // }
      // const updater = new URLSearchParams()
      // updater.append('searchQuery', this.searchQuery)
      // axios.post(process.env.VUE_APP_ROOT_API + '/search_kitchen', updater, authorization).then(response => {
      //   this.$store.dispatch('setShowLoading', false)
      //   var data = response.data
      //   for (var i = 0; i < data.length; i++) {
      //     toReturn.push({
      //       id: data[i].id,
      //       title: data[i].title,
      //       subTitle: data[i].sub_title,
      //       numberOfWorkouts: data[i].numberOfWorkouts,
      //       numberOfCategories: data[i].numberOfCategories
      //     })
      //   }
      //   this.kitchenWorkoutGroups = toReturn
      // }).catch(error => {
      //   this.$store.dispatch('setTokenExpired', true)
      //   console.log('Error 1', error)
      // })
    },
    goToKitchenWorkoutGroup (kitchenWorkoutGroup) {
      this.$router.push('/kitchenworkoutgroup/' + kitchenWorkoutGroup.id)
    },
    getKitchenWorkoutGroups () {
      this.$store.dispatch('setShowLoading', true)
      var toReturn = []
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      axios.get(process.env.VUE_APP_ROOT_API + '/kitchen-workout-groups', authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data
        for (var i = 0; i < data.length; i++) {
          toReturn.push({
            id: data[i].id,
            title: data[i].title,
            subTitle: data[i].sub_title,
            numberOfWorkouts: data[i].numberOfWorkouts,
            numberOfCategories: data[i].numberOfCategories
          })
        }
        this.kitchenWorkoutGroups = toReturn
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error', error)
      })
    }
  },
  created () {
  },
  mounted () {
    this.getKitchenWorkoutGroups()

    // this.$store.dispatch('getKitchenWorkoutGroups')
  }
}
</script>

<style lang="scss">

</style>
