<template>
  <div class="LoginPage h-full">
    <div class="w-full bg-primary flex px-8 md:px-24 pb-8 md:pb-24 pt-24">
      <div class="w-full">
        <Logo heightClass="h-8 mb-2" />
        <h3 class="font-extrabold leading-none">welcome</h3>
        <h3 class="font-extrabold leading-none">back</h3>
        <h3 class="font-extrabold leading-none">legend.</h3>
      </div>
    </div>
    <div class="p-12 md:p-24">
      <div v-if="!messageSent&&!messageFailed" class="w-full md:w-1/4">
        <LoginForm @onSubmit="loginUser" />
      </div>
      <div v-if="messageSent" class="w-full">
        <h3 class="text-xl md:text-3xl text-center">Successfully logged in</h3>
      </div>
      <div v-if="messageFailed" class="w-full">
        <h3 class="text-xl md:text-3xl text-center text-red-500">Login Failed!</h3>
      </div>
    </div>

    <div class="p-12 md:p-24 text-primary">
      <p class="uppercase tracking-widest">Don't have a membership? <span @click="goToSignUp" class="font-bold cursor-pointer">Signup</span></p>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import shared from '@/shared'
import axios from 'axios'
import Logo from '@/components/icons/Logo.vue'
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'login',
  components: {
    Logo,
    LoginForm
  },
  data () {
    return {
      messageSent: false,
      messageFailed: false
    }
  },
  computed: {
  },
  methods: {
    loginUser (obj) {
      const updater = new URLSearchParams()
      updater.append('login', obj.email)
      updater.append('password', obj.password)
      axios.post(process.env.VUE_APP_ROOT_API + '/api/auth/login', updater).then(response => {
        response.data.user.haveLoggedIn = shared.getBool(response.data.user.have_logged_in)
        this.$store.dispatch('setToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)
        this.messageSent = true
        this.messageFailed = false

        if (!response.data.user.haveLoggedIn) {
          this.$router.push('/howto')
        }
        // this.$router.push('/profile/0')
      }).catch(error => {
        this.messageSent = false
        this.messageFailed = true
        console.log('Error 1', error)
      })
    },
    goToSignUp () {
      window.location = '/signup'
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
