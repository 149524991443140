<template>
  <div class="profile h-full">
    <div class="w-full bg-primary flex px-8 md:px-24 pb-40 pt-24">
      <div class="w-full">
        <Logo heightClass="h-8 mb-2" />
          <h3 class="font-extrabold leading-none">membership plan.</h3>
      </div>
    </div>
    <div class="px-12 md:px-24">
      <div class="w-full lg:w-2/3 -mt-24">

        <div class="w-full h-24 bg-kitchen text-white rounded-t-xl flex flex-wrap justify-center items-center text-center">
          <div>
            <h3 class="text-2xl lg:text-2xl xl:text-4xl font-extrabold leading-none">{{product.name}}</h3>
            <p class="text-xs md:text-base uppercase tracking-widest font-bold">Membership Plan</p>
          </div>
        </div>

        <div class="w-full bg-white text-primary border-l-2 border-r-2 border-kitchen">
          <div class="flex flex-wrap p-4 lg:p-10">
            <div
              v-for="(feature, index) in featureList" :key="index"
              class="w-full xl:w-1/2 flex items-center mt-4 md:pr-4"
            >
              <div>
                <TickIcon heightClass="h-5" />
              </div>
              <p class="ml-4 text-xs md:text-base uppercase tracking-widest">{{feature}}</p>
            </div>
          </div>
          <div v-if="product.plan" class="flex border-t border-kitchen">
            <div class="w-1/2 flex items-center px-2 md:px-4 py-2">
              <h2 class="text-3xl md:text-4xl font-extrabold text-kitchen">{{price}}</h2>
              <p class="ml-2 md:ml-4 text-xs md:text-base uppercase tracking-widest leading-tight">Per<br />{{product.plan.interval}}</p>
            </div>
            <div class="w-1/2 flex items-center px-2 md:px-4 py-2 border-l border-kitchen">
              <h2 class="text-3xl md:text-4xl font-extrabold text-kitchen">{{product.plan.trial_period_days}}</h2>
              <p class="ml-2 md:ml-4 text-xs md:text-base uppercase tracking-widest leading-tight">Days<br />Free</p>
            </div>
          </div>
        </div>

        <div @click="goToSignUp" class="cursor-pointer w-full h-24 bg-kitchen hover:bg-primary text-white rounded-b-xl flex flex-wrap justify-center items-center text-center">
          <div>
            <p class="text-xs md:text-base uppercase tracking-widest font-bold">Get Started!</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import shared from '@/shared'
import axios from 'axios'
import Logo from '@/components/icons/Logo.vue'
import TickIcon from '@/components/icons/TickIcon.vue'

export default {
  name: 'plan',
  components: {
    Logo,
    TickIcon
  },
  data () {
    return {
      product: {},
      featureList: [
        '12 Week Start Up Program',
        'Weekly Training Schedule',
        'HIIT Workouts',
        'Strength & Resistance Training',
        'Boxing Sessions',
        'Running Program',
        'Health Wellness Advice',
        'Recipes & Nutritional Support',
        'Online Accountability & Motivation',
        'Private Online Community ',
        'Weekly Live Online Workout',
        'Weekly Live Q&A with Qualified Fitness Professionals'
      ]
    }
  },
  computed: {
    price () {
      if (this.product.plan) {
        var toReturn = shared.formatPrice(parseInt(this.product.plan.actual_amount), '$')
        toReturn = toReturn.split('.')[0]
        return toReturn
      } else {
        return ''
      }
    }
  },
  methods: {
    goToSignUp () {
      this.$router.push('/signup')
    },
    getProduct () {
      axios.get(process.env.VUE_APP_ROOT_API + '/stripe_plan').then(response => {
        this.product = response.data
      }).catch(error => {
        this.$store.dispatch('setTokenExpired', true)
        console.log('Error 1', error)
      })
    }
  },
  mounted () {
    this.getProduct()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
