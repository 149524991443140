<template>
  <svg
    class="fill-current stroke-current"
    :class="classObject"
    viewBox="0 0 100 100"
    @click="$emit('onClick')"
  >
    <g transform="matrix(1,0,0,1,-1.58831,5.01906)">
      <rect x="13.596" y="41.296" width="75.985" height="7.37"/>
    </g>
    <g transform="matrix(6.12323e-17,1,-1,6.12323e-17,94.9809,-1.58831)">
      <rect x="13.596" y="41.296" width="75.985" height="7.37"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PlusIconTwo',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-6'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
