<template>
  <span class="no-underline leading-tight text-4xl md:text-5xl lowercase font-extrabold text-white hover:text-gray-400">
    {{word}}<span :class="dotColor">.</span>
  </span>
</template>

<script>
export default {
  name: 'NavLinkHeading',
  props: {
    word: {
      type: String,
      default: 'word'
    },
    dotColor: {
      type: String,
      default: 'text-white'
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
