<template>
  <svg
    class="fill-current"
    :class="classObject"
    viewBox="0 0 50 59.3"
    @click="$emit('onClick')"
  >
    <path class="st0" d="M25,53c-6.7,0-12.9-2.4-17.8-6.3v12.6l17.5-3.9l17.5,3.9V47.1C37.5,50.8,31.5,53,25,53z"/>
    <path class="st0" d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M33.1,33.5c-0.8,1.2-1.9,2.2-3.4,2.9
      c-1.5,0.7-3.5,1.1-5.7,1.1c-1.7,0-3.3-0.2-4.9-0.6s-3-1-4.2-1.7l2.4-5c0.9,0.6,2,1.1,3.1,1.5c1.1,0.3,2.3,0.5,3.4,0.5
      c1.1,0,2.1-0.2,2.8-0.7c0.7-0.4,1-1.1,1-1.8c0-0.8-0.3-1.4-1-1.8s-1.9-0.6-3.6-0.6h-6.4l1.2-13.8h15.1v5.2h-9.5l-0.3,3.4h1.6
      c3.3,0,5.8,0.7,7.3,2c1.6,1.4,2.4,3.2,2.4,5.4C34.2,31,33.8,32.3,33.1,33.5z"/>
  </svg>
</template>

<script>
export default {
  name: 'BadgeIcon500',
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    heightClass: {
      type: String,
      default: 'h-64'
    }
  },
  computed: {
    classObject () {
      return [
        this.heightClass,
        {
          'cursor-pointer': this.isLink
        }
      ]
    }
  },
  methods: {
  }
}
</script>
