<template>
  <div class="home">
    <!-- video modal -->
    <div
      v-for="(video, index) in videos" :key="index"
      @click="video.open=false"
      class="z-30 fixed h-screen w-full items-center justify-center transition-all duration-500"
      :class="{
        'videoModalBackground opacity-100 flex':video.open,
        'opacity-0 hidden':!video.open,
      }"
    >
      <div v-if="video.open" class="absolute p-4 md:p-12 w-full text-white rounded-xl">
        <div class="mb-12 md:mb-24">
          <iframe class="w-full" :height="videoHeight" :src="video.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div
      @click="videoOpenPreview=false"
      class="z-30 fixed h-screen w-full items-center justify-center transition-all duration-500"
      :class="{
        'videoModalBackground opacity-100 flex':videoOpenPreview,
        'opacity-0 hidden':!videoOpenPreview,
      }"
    >
      <div v-if="videoOpenPreview" class="absolute p-4 md:p-12 w-full text-white rounded-xl">
        <div class="mb-12 md:mb-24">
          <iframe class="w-full" :height="videoHeight" :src="videoUrlPreview" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div
      :style="{ backgroundImage: 'url('+headerImageSrc+')' }"
      class="bg-cover bg-top h-screen min-h-screen bg-gray-300 flex flex-col justify-between px-8 py-8 md:px-24 md:py-24"
    >
      <div class="headingLogo w-full flex-1 flex items-center md:items-end">
        <Logo heightClass="lg:h-40" />
      </div>
      <div class="w-full">
        <h1 class="text-5xl md:text-7xl lg:text-9xl uppercase font-extrabold leading-none">Ready<br />to<br />take<br />control?</h1>
      </div>
    </div>

    <div
      class="bg-black text-white flex flex-wrap justify-center items-center text-center px-12 sm:px-12 lg:px-40 xl:px-64 xxl:px-112 pt-40 pb-40"
    >
      <p class="w-full text-base md:text-3xl uppercase leading-loose tracking-widest">Less than 10% of people are happy with their health and struggle to find a program they can stick to.</p>
      <p class="mt-8 w-full text-xs md:text-xl uppercase leading-loose tracking-widest">{{aboutText}}</p>
      <div class=" mt-12 mb-12">
        <h4 @click="goToPlan" class="cursor-pointer text-base md:text-lg uppercase border border-white px-4 md:px-10 py-2">Start Your Free Trial</h4>
      </div>
    </div>

    <div
      class="hidden md:flex h-96 bg-kitchen-dark text-white justify-center items-center text-center px-12 sm:px-12 lg:px-12 xl:px-64 xxl:px-112 pb-24 pt-0 flex-wrap"
    >
      <div class="w-1/3 flex flex-wrap justify-center items-center">
        <h3 class="-mt-20 uppercase text-xl lg:text-2xl xl:text-3xl font-extrabold">{{previewHeading}}</h3>
        <div class="mt-4">
          <h4 @click="videoOpenPreview=true" class="cursor-pointer text-sm md:text-base uppercase border border-white px-4 md:px-10 py-2">CLICK to see Preview</h4>
        </div>
      </div>
      <div class="w-1/3 flex justify-center items-center">
        <img @click="videoOpenPreview=true" class="cursor-pointer -mt-18 mb-12 w-64" :src="videoThumbnailPreview" alt="">
      </div>
      <div class="w-1/3 flex justify-center items-center">
        <p class="-mt-24 text-xs lg:text-base uppercase leading-loose tracking-widest">{{previewText}}</p>
      </div>
    </div>

    <div
      class="flex md:hidden bg-kitchen-dark text-white justify-center items-center text-center px-12 sm:px-12 lg:px-12 xl:px-64 xxl:px-112 pb-24 pt-0 flex-wrap"
    >
      <div class="w-full flex justify-center items-center">
        <img @click="videoOpenPreview=true" class="cursor-pointer -mt-18 mb-12 w-56" :src="videoThumbnailPreview" alt="">
      </div>
      <div class="w-full flex justify-center items-center">
        <h3 class="uppercase text-xl lg:text-2xl xl:text-3xl font-extrabold">{{previewHeading}}</h3>
      </div>
      <div class="w-full flex justify-center items-center">
        <p class="text-xs lg:text-base uppercase leading-loose tracking-widest">{{previewText}}</p>
      </div>
      <div class="my-4">
        <h4 @click="videoOpenPreview=true" class="cursor-pointer text-sm md:text-base uppercase border border-white px-4 md:px-10 py-2">CLICK to see Preview</h4>
      </div>
    </div>

    <div
      class="bg-white text-black px-12 md:px-24 xl:px-40 xxl:px-64 py-24 flex flex-wrap"
    >
      <div class="w-full text-center px-2 md:px-8 xl:px-24">
        <h3 class="my-12 uppercase text-xl lg:text-2xl xl:text-3xl font-extrabold leading-8">See what the legends are saying</h3>
        <div v-for="(testimonial, index) in testimonials"
          :key="index"
          class="w-full"
        >
          <p
            v-if="currentTestimonial==index"
            class="text-xs md:text-base leading-loose tracking-widest text-gray-600"
          >
            {{testimonial.content}}
            <br />
            <br />
            - {{testimonial.author}}
          </p>
        </div>
      </div>
      <div class="w-full flex justify-center mt-8">
        <div
          v-for="(testimonial, index) in testimonials" :key="index"
          @click="currentTestimonial=index"
          class="cursor-pointer w-4 h-4 rounded-full"
          :class="{
            'bg-gym ':currentTestimonial!=index,
            'border-2 border-gym':currentTestimonial==index,
            'mr-4 md:mr-8':index!=testimonials.length-1
          }"
        >
        </div>
      </div>
    </div>

    <div
      :style="{ backgroundImage: 'url(/img/ept_legends-workout-DSC_0595-c.jpg)' }"
      class="bannerOne bg-cover h-72 md:h-144 bg-gray-300 flex flex-wrap items-center justify-center"
    >
      <div class="flex flex-wrap justify-center">
        <Logo heightClass="h-8 md:h-12" />
        <h3 class="px-8 mt-4 w-full text-center uppercase text-xl lg:text-2xl xl:text-5xl font-extrabold leading-8">{{fillerText}}</h3>
      </div>
    </div>

    <div class="flex lg:hidden w-full flex-wrap">
      <div class="w-full bg-gym flex justify-center pt-8">
        <div class="w-2/6 flex justify-center items-end">
          <img @click="goToLink(overviewGymLink)" class="cursor-pointer h-28" src="/img/ept-mobile-mockup-gym-cropped.png" alt="">
        </div>
        <div class="w-4/6 flex flex-wrap text-center pb-4">
          <h3 class="w-full px-8 text-center uppercase text-xl lg:text-2xl xl:text-5xl font-extrabold leading-8">Gym</h3>
          <p class="w-full uppercase text-xs px-4">{{overviewGymContent}}</p>
          <div class="w-full pt-4 flex justify-center">
            <h4 @click="goToLink(overviewGymLink)" class="cursor-pointer text-xs md:text-sm uppercase border border-white px-4 md:px-10 py-1">Show</h4>
          </div>
        </div>
      </div>
      <div class="w-full bg-academy flex justify-center pt-8">
        <div class="w-2/6 flex justify-center items-end">
          <img @click="goToLink(overviewAcademyLink)" class="cursor-pointer h-28" src="/img/ept-mobile-mockup-academy-cropped.png" alt="">
        </div>
        <div class="w-4/6 flex flex-wrap text-center pb-4">
          <h3 class="w-full px-8 text-center uppercase text-xl lg:text-2xl xl:text-5xl font-extrabold leading-8">Academy</h3>
          <p class="w-full uppercase text-xs px-4">{{overviewAcademyContent}}</p>
          <div class="w-full pt-4 flex justify-center">
            <h4 @click="goToLink(overviewAcademyLink)" class="cursor-pointer text-xs md:text-sm uppercase border border-white px-4 md:px-10 py-1">Show</h4>
          </div>
        </div>
      </div>
      <div class="w-full bg-kitchen flex justify-center pt-8">
        <div class="w-2/6 flex justify-center items-end">
          <img @click="goToLink(overviewKitchenLink)" class="cursor-pointer h-28" src="/img/ept-mobile-mockup-kitchen-cropped.png" alt="">
        </div>
        <div class="w-4/6 flex flex-wrap text-center pb-4">
          <h3 class="w-full px-8 text-center uppercase text-xl lg:text-2xl xl:text-5xl font-extrabold leading-8">Kitchen</h3>
          <p class="w-full uppercase text-xs px-4">{{overviewKitchenContent}}</p>
          <div class="w-full pt-4 flex justify-center">
            <h4 @click="goToLink(overviewKitchenLink)" class="cursor-pointer text-xs md:text-sm uppercase border border-white px-4 md:px-10 py-1">Show</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden lg:flex w-full flex-wrap h-128">
      <div class="w-full lg:w-1/3 h-full bg-gym flex flex-col justify-between pt-8">
        <div class="w-full flex flex-wrap text-center">
          <h3 class="w-full px-8 mt-4 text-center uppercase text-xl lg:text-2xl xl:text-5xl font-extrabold leading-8">Gym</h3>
          <p class="w-full uppercase mt-4 px-8 lg:px-24">{{overviewGymContent}}</p>
          <div class="w-full my-4 flex justify-center">
            <h4 @click="goToLink(overviewGymLink)" class="cursor-pointer text-xs md:text-sm uppercase border border-white px-4 md:px-10 py-1">Show</h4>
          </div>
        </div>
        <div class="hidden lg:flex w-full justify-center">
          <img @click="goToLink(overviewGymLink)" class="cursor-pointer w-40 xl:w-56" src="/img/ept-mobile-mockup-gym-cropped.png" alt="">
        </div>
      </div>
      <div class="w-full lg:w-1/3 h-full bg-academy flex flex-col justify-between pt-8">
        <div class="w-full flex flex-wrap text-center">
          <h3 class="w-full px-8 mt-4 text-center uppercase text-xl lg:text-2xl xl:text-5xl font-extrabold leading-8">Academy</h3>
          <p class="w-full uppercase mt-4 px-8 lg:px-24">{{overviewAcademyContent}}</p>
          <div class="w-full my-4 flex justify-center">
            <h4 @click="goToLink(overviewAcademyLink)" class="cursor-pointer text-xs md:text-sm uppercase border border-white px-4 md:px-10 py-1">Show</h4>
          </div>
        </div>
        <div class="hidden lg:flex w-full justify-center">
          <img @click="goToLink(overviewAcademyLink)" class="cursor-pointer w-40 xl:w-56" src="/img/ept-mobile-mockup-academy-cropped.png" alt="">
        </div>
      </div>
      <div class="w-full lg:w-1/3 h-full bg-kitchen flex flex-col justify-between pt-8">
        <div class="w-full flex flex-wrap text-center">
          <h3 class="w-full px-8 mt-4 text-center uppercase text-xl lg:text-2xl xl:text-5xl font-extrabold leading-8">Kitchen</h3>
          <p class="w-full uppercase mt-4 px-8 lg:px-24">{{overviewKitchenContent}}</p>
          <div class="w-full my-4 flex justify-center">
            <h4 @click="goToLink(overviewKitchenLink)" class="cursor-pointer text-xs md:text-sm uppercase border border-white px-4 md:px-10 py-1">Show</h4>
          </div>
        </div>
        <div class="hidden lg:flex w-full justify-center">
          <img @click="goToLink(overviewKitchenLink)" class="cursor-pointer w-40 xl:w-56" src="/img/ept-mobile-mockup-kitchen-cropped.png" alt="">
        </div>
      </div>
    </div>

    <div
      class="bg-black text-white px-12 md:px-24 xl:px-40 xxl:px-64 py-24 flex flex-wrap"
    >
      <div class="w-full">
        <div class="w-full text-center px-2 md:px-8 xl:px-24">
          <p class="text-xs md:text-base uppercase leading-loose tracking-widest">How EPT Legends is</p>
          <h3 class="uppercase text-xl lg:text-2xl xl:text-3xl font-extrabold">Built for you</h3>
        </div>
        <div
          v-for="(video, index) in videos" :key="index"
          class="flex justify-center"
        >
          <div
            v-if="currentVideo==index"
            :style="{ backgroundImage: 'url(' + video.thumbnail + ')' }"
            class="my-4 w-96 h-64 relative bg-cover bg-no-repeat bg-center bg-gray-400"
          >
            <div
              @click="openVideo(index)"
              class="
              w-full h-full
              flex
              absolute
              top-0
              left-0
              bg-black
              items-center
              justify-center
              cursor-pointer
              opacity-50
              hover:opacity-25
              transition duration-300 ease-in-out
              "
            >
              <div class="text-white">
                <PlayIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center mt-8">
        <div
          v-for="(video, index) in videos" :key="index"
          @click="currentVideo=index"
          class="cursor-pointer w-4 h-4 rounded-full"
          :class="{
            'bg-white ':currentVideo!=index,
            'border-2 border-white':currentVideo==index,
            'mr-4 md:mr-8':index!=videos.length-1
          }"
        >
        </div>
      </div>
    </div>

    <div
      class="bg-white text-black justify-center items-center text-center px-12 lg:px-64 pb-24 pt-0 flex flex-wrap"
    >
      <div class="w-full text-black mt-12">
        <h6 class="uppercase font-bold">Meet your trainers</h6>
      </div>

      <div class="w-full text-black flex flex-wrap justify-center">

        <div v-for="(trainer, index) in trainers" :key="index" class="mt-12 w-full md:w-1/2 flex flex-wrap justify-center md:justify-end">
          <div class="px-0 md:px-12 flex flex-wrap justify-center">
            <div :style="{ backgroundImage: 'url('+trainer.imageSrc+')' }" class="w-64 h-64 bg-gray-400 bg-cover bg-center">
            </div>
            <div class="w-full mt-4">
              <h6 class="uppercase font-bold">{{trainer.name}}</h6>
              <p class="text-xs md:text-sm px-0 xxl:px-24">
                {{trainer.description}}
              </p>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="w-full flex flex-wrap">
      <div
        class="w-full md:w-1/2 bg-primary text-white flex flex-wrap justify-center items-center text-center px-12 xl:px-40 py-24"
      >
        <div @click="goToPlan" class="w-full mb-4">
          <h3 class="cursor-pointer uppercase text-xl lg:text-2xl xl:text-2xl font-extrabold">GET STARTED</h3>
          <h3 class="cursor-pointer uppercase text-xl lg:text-2xl xl:text-2xl font-extrabold">with your 7-day trial</h3>
        </div>
        <TrialIcon class="w-full" @onClick="goToPlan" heightClass="h-24" isLink/>
      </div>

      <div
        class="w-full md:w-1/2 bg-blog text-white flex flex-wrap justify-center items-center text-center px-12 xl:px-40 py-24"
      >
        <div @click="goToBlog" class="w-full">
          <h3 class="cursor-pointer uppercase text-xl lg:text-2xl xl:text-2xl font-extrabold">Learn More On Our</h3>
        </div>
        <div class="w-full flex justify-center">
          <h4 @click="goToBlog" class="cursor-pointer text-xs md:text-2xl uppercase font-bold border-2 border-white px-4 md:px-10 py-1">Legends Blog</h4>
        </div>
      </div>
    </div>

    <div class="w-full">
      <Footer />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/icons/Logo.vue'
import PlayIcon from '@/components/icons/PlayIcon.vue'
import Footer from '@/components/Footer.vue'
import TrialIcon from '@/components/icons/TrialIcon.vue'
import axios from 'axios'

export default {
  name: 'home',
  components: {
    Logo,
    PlayIcon,
    TrialIcon,
    Footer
  },
  data () {
    return {
      headerImageSrc: '',
      aboutText: '',
      fillerText: '',
      previewHeading: '',
      previewText: '',
      videoOpenPreview: false,
      videoUrlPreview: '',
      videoThumbnailPreview: '',
      currentVideo: 0,
      videos: [
      ],
      currentTestimonial: 0,
      testimonials: [
      ],
      trainers: [
      ],
      overviewGymContent: '',
      overviewAcademyContent: '',
      overviewKitchenContent: '',
      overviewGymLink: '',
      overviewAcademyLink: '',
      overviewKitchenLink: ''
    }
  },
  computed: {
    videoHeight () {
      var toReturn = '300'
      if (this.$mq === 'sm') toReturn = '300'
      else if (this.$mq === 'md') toReturn = '400'
      else if (this.$mq === 'lg') toReturn = '400'
      else if (this.$mq === 'xl') toReturn = '400'
      else if (this.$mq === 'xxl') toReturn = '800'
      else if (this.$mq === 'xxxl') toReturn = '800'
      return toReturn
    }
  },
  methods: {
    getHomePage () {
      this.trainers = []
      this.videos = []
      this.testimonials = []
      this.headerImageSrc = ''
      this.aboutText = ''
      this.previewHeading = ''
      this.previewText = ''
      this.videoUrlPreview = ''
      this.videoThumbnailPreview = ''
      this.fillerText = ''
      this.overviewGymContent = ''
      this.overviewAcademyContent = ''
      this.overviewKitchenContent = ''
      this.overviewGymLink = ''
      this.overviewAcademyLink = ''
      this.overviewKitchenLink = ''

      this.$store.dispatch('setShowLoading', true)
      const authorization = {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      }
      axios.get(process.env.VUE_APP_ROOT_API + '/home_page_data', authorization).then(response => {
        this.$store.dispatch('setShowLoading', false)
        var data = response.data.trainers
        var toReturn = []
        for (var i = 0; i < data.length; i++) {
          toReturn.push({
            id: data[i].id,
            name: data[i].name,
            description: data[i].description
          })
          if (data[i].image) toReturn[toReturn.length - 1].imageSrc = data[i].image.path
        }
        this.trainers = toReturn

        data = response.data.videos
        toReturn = []
        for (i = 0; i < data.length; i++) {
          toReturn.push({
            id: data[i].id,
            open: false,
            url: data[i].url
          })
          if (data[i].thumbnail) toReturn[toReturn.length - 1].thumbnail = data[i].thumbnail.path
        }
        this.videos = toReturn

        data = response.data.testimonials
        toReturn = []
        for (i = 0; i < data.length; i++) {
          toReturn.push({
            id: data[i].id,
            content: data[i].content,
            author: data[i].author
          })
        }
        this.testimonials = toReturn

        data = response.data.homepage
        if (data.header_image) this.headerImageSrc = data.header_image.path
        this.aboutText = data.about_text
        this.previewHeading = data.preview_heading
        this.previewText = data.preview_text
        this.videoUrlPreview = data.preview_video_url
        if (data.preview_video_thumbnail) this.videoThumbnailPreview = data.preview_video_thumbnail.path
        this.fillerText = data.filler_text
        this.overviewGymContent = data.overview_content_gym
        this.overviewAcademyContent = data.overview_content_academy
        this.overviewKitchenContent = data.overview_content_kitchen
        this.overviewGymLink = data.overview_link_gym
        this.overviewAcademyLink = data.overview_link_academy
        this.overviewKitchenLink = data.overview_link_kitchen
      }).catch(error => {
        console.log('Error', error)
      })
    },
    goToPlan () {
      this.$router.push('/plan')
    },
    goToBlog () {
      this.$router.push('/blog')
    },
    goToLink (link) {
      window.open(link, '_blank')
    },
    openVideo (index) {
      for (var i = 0; i < this.videos.length; i++) {
        this.videos[i].open = false
      }
      this.videos[index].open = true
    }
  },
  created () {
  },
  mounted () {
    this.getHomePage()
  }
}
</script>

<style lang="scss">
  .home {
    .videoModalBackground {
      background-color: rgba(0, 0, 0, 0.9);
    }

    .bannerOne {
      background-position: 50%  30%;
    }

    .headingLogo {
      padding-bottom: 0px;
    }

    @media (min-height: '1000px') {
      .headingLogo {
        padding-bottom: 100px;
      }
    }
  }

</style>
