<template>
  <div class="flex space-x-1 md:space-x-1 lg:space-x-2">
    <div
      v-for="index in maxRatingNumber" :key="index"
      class="
        w-2
        h-2
        md:w-3
        md:h-3
        lg:w-4
        lg:h-4
        rounded-full"
      :class="[
        getClass(index)
      ]"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: 'RatingScale',
  props: {
    // maxRating: [String, Number],
    maxRating: {
      type: [String, Number],
      default: 5
    },
    rating: [String, Number],
    dotColor: {
      type: String,
      default: 'bg-gray-400'
    },
    circleColor: {
      type: String,
      default: 'border-gray-400'
    }
  },
  computed: {
    maxRatingNumber () {
      return parseInt(this.maxRating)
    },
    ratingNumber () {
      return parseInt(this.rating)
    }
  },
  methods: {
    getClass (whichDot) {
      if (this.ratingNumber < whichDot) {
        return 'border md:border-2 ' + this.circleColor
      } else {
        return this.dotColor
      }
    }
  }
}
</script>
